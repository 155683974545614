@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 15px;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.link_deco {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.sea-ticket-list-face-value {
  display: none;
}

.link_deco:hover {
  color: darkblue;
  /* Optional: change color on hover */
}

.sea-highlighted-row .sea-superhighlight-text {
  display: none;
}

a:hover,
button:hover,
input:hover {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.form-control:focus {
  outline: 0;
  box-shadow: none;
}

a:hover,
a:focus,
input:focus,
select:focus,
button:focus {
  outline: none;
  box-shadow: none;
  text-decoration: none;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #000000;
  margin: 0px 0px 0px 0px;
}

.btn-primary {
  background: #E33CC7;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 0;
  text-transform: capitalize;
  font-weight: 600;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: #080015;
  box-shadow: none;
  border: 0;
}

.btn-outline-primary {
  background: none;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  border: 2px solid #fff
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  background: #E33CC7;
  color: #fff;
  box-shadow: none;
  border: 2px solid #E33CC7;
}

/*=--=-=-==-=-=---=-=-
Header Css
-=-=-=-=-=-=-=-*/



.topBar {
  background: #080015;
  color: #fff;
  padding: 0.5rem 2rem;
}

.topBar li span {
  max-width: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: .5rem
}

.topBar li,
.topBar li a {
  font-weight: 600;
  color: #fff;
}

.topBar li a {
  text-transform: uppercase;
  margin-left: 0.7rem;
  padding-left: 0.7rem;
  position: relative;
}

.topBar li:last-child a::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 12px;
  width: 1px;
  background: #fff;
  content: '';
}

.topBar .dropdown-toggle {
  background: none;
  padding: 0;
}

.topBar .dropdown-menu {
  padding: 0;
  border: 0;
  right: 0 !important;
  left: auto !important;
  min-width: 100%;
  margin-top: 6px !important
}

.topBar .dropdown-item {
  background: #f45e16;
  color: #fff;
  position: relative;
  padding: 0.5rem 1rem;
  display: block;
}

.topBar .dropdown-item::before {
  border-bottom: 8px solid #f45e16;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  width: 0;
  height: 0;
  content: '';
  top: -8px;
  left: 0;
  right: 0;
  margin: auto;
}

.mainNav {
  padding: 1.5rem 2rem 0
}

.mainNav hr {
  border-top: 1px solid #ffffff90;
  margin-top: 1rem;
  margin-bottom: 0;
}

.navbar {
  padding: 0
}

.navbar-nav {
  align-items: center;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  margin-left: 1.5rem;
  padding: 0.5rem 0;
  font-weight: 600;
  position: relative;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #fff;
}



/*.navbar-light .navbar-nav .nav-link:last-child {
  border: 2px solid #fff;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  background: #fff;
  color: #242424;
}

.navbar-light .navbar-nav .nav-link:last-child:hover,
.navbar-light .navbar-nav .nav-link:last-child:focus {
  background: #E33CC7;
  border: 2px solid #E33CC7;
  color: #ffffff;
}

.navbar-light .navbar-nav .nav-link:last-child:after {
  display: none
}
 */

.carousel-inner {
  background: #080015;
}

.carousel-inner .locationTag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem
}

.carousel-inner .locationTag strong {
  font-size: 20px;
  line-height: 45px;
}

.carousel-inner .locationTag i {
  vertical-align: middle;
}

.carousel-inner .locationTag .btn-primary {
  background: none;
  text-transform: none;
  font-weight: 400;
  border-radius: 0;
  border: 0;
  padding: 0;
  margin-left: 1rem;
  border-bottom: 1px solid #fff;
}

.locationTag .dropdown-toggle::after {
  display: none;
}

.carousel-item img {
  opacity: 0.1;
}

.carousel-caption {
  color: #fff;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  width: 100%;
}

.carousel-caption h1 {
  font-size: 65px;
  color: #fff;
  line-height: 90px;
  margin-bottom: 3rem;
}

.carousel-caption form {
  width: 70%;
  max-width: 1000px;
  margin: auto;
}

.carousel-caption .form-group {
  position: relative;
}

/* .carousel-caption .form-group i {
  position: absolute;
  left: 1rem;
  color: #060606;
  opacity: 0.6;
  top: 0.8rem;
} */

.carousel-caption .form-control {
  /* border-radius: 10px;
  -webkit-border-radius: 10px;
  height: 45px; */
  font-size: 16px;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
  display: none;
}

/*-=-=--=-=-=--=-
Upcoming Events Css
-=-=-=-=-=-=-=-*/

section {
  padding: 4rem 2rem;
}

.sectionTitle {
  text-align: center;
  /* margin-bottom: 2rem */
}

.sectionTitle h2 {
  position: relative;
  margin-bottom: 2rem;
  font-size: 40px;
  line-height: 50px;
  /* text-transform: capitalize; */
  padding-bottom: 0.7rem
}

.sectionTitle h2:before,
.sectionTitle h2:after {
  left: 0;
  right: 0;
  margin: auto;
  content: '';
  height: 1px;
  background: #000000;
  position: absolute;
  bottom: 0;
  width: 220px;
}

.sectionTitle h2:after {
  bottom: -10px;
  width: 200px;
}

.eventCol {
  position: relative;
  background: #fff;
  border-radius: 15px;
  /* border: solid black 1px; */
}

.eventDesc {
  /*position: absolute;*/
  left: 0;
  /* background: #00000060; */
  color: #000;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
}

.eventCol:hover {
  background: #ddd;
}

.eventDesc h5 {
  color: #000;
}

.eventDesc p,
.eventDesc h3 {
  display: flex;
  flex: auto;
  color: #000;
  margin: 0;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start
}

.eventDesc .eventDate {
  background: rgba(9, 0, 20, .7);
  color: #000;
  padding: 0.5rem;
  /*font-size: 15px;*/
  font-weight: 600;
  /*margin: 0 0 0 0.8rem;*/
  width: 55px;
  text-transform: capitalize;
  white-space: normal;
  text-align: center
}

.eventDesc .eventDate strong {
  font-size: 20px;
  display: block;
}

.eventDesc .eventPrice {
  background: #f45e16;
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 0.2rem;
  text-align: center;
  color: #fff;
  margin-top: 0.8rem;
  margin-left: 0.4rem;
  font-weight: 600;
  border-radius: 10px;
}

.eventPriceButton {
  font-size: 1rem;
}

.eventDesc h3 {
  padding: 0.8rem 0.8rem 1rem;
  line-height: 1.6;
  align-items: center
}

.eventDesc h3 span {
  font-size: 18px;
  /* flex: 0 0 80%; */
  font-weight: 600;
}

.eventDesc h3 small {
  font-size: 14px;
  font-weight: 400;
  display: block
}

.eventDesc .btn-primary {
  font-weight: 600;
  text-transform: Capitalize;
  padding: 0.5rem 1rem;
}

/*-=-=-=-=-=-==-=-=-=
Events Main Css
-=-=-=-=-=-=*/
.eventsMain {
  background-color: #ffffff;
  background-size: cover;
  background-attachment: fixed;
}

.eventsMain .sectionTitle h2 {
  color: #fff;
  margin-bottom: 3.5rem
}

.eventsMain .sectionTitle h2::before {
  width: 100px;
}

.eventsMain .sectionTitle h2::after {
  width: 80px;
}

.eventList {
  display: block;
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  /*text-transform: uppercase;*/
  border-radius: 30px;
  -webkit-border-radius: 30px;
  overflow: hidden;
  border: 2px solid #4f176d;
  position: relative;
  margin-bottom: 2rem;
  height: 200px;
}

.eventList .titleOverlay {
  background: rgba(0, 0, 0, .7);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.eventList:hover {
  color: #ffffff
}

.eventList:hover .titleOverlay {
  background: rgba(0, 0, 0, .4)
}

/*-=-=-=-=-=-
Cta Css
-=-==-=-=-=-=*/

.cta {
  background: #fcf6fe;
}

.btn-larger {
  font-size: 1.4rem;
  width: 300px;
}

.cta h2 {
  font-size: 40px;
  line-height: 55px;
}

.cta p {
  font-size: 22px;
}

.cta .btn-primary,
.newsMain .btn-primary {
  min-width: 150px;
}

/*-=-=-=-=-==
News Css
-=-=-=-=-=-=*/

.newsCol {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, .15);
  text-align: center;
  padding: 0.7rem 0.7rem 2rem;
  margin-bottom: 0;
  height: 100%;
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, .08);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .08);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.newsCol .imageWrap {
  position: relative;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  overflow: hidden;
  margin-bottom: 1.2rem;
  min-height: 220px;
}

.newsCol .imageWrap img {
  object-fit: cover;
  height: 220px;
}

.newsCol.h-auto .imageWrap {
  min-height: 0;
}

.newsCol.h-auto .imageWrap img {
  object-fit: none;
  height: auto;
}

.newsCol .imageWrap a {
  color: initial;
  display: block;
}

.imageWrap .dateTag {
  background: rgba(244, 94, 22, .9);
  color: #fff;
  padding: 0.5rem;
  font-size: 15px;
  font-weight: 600;
  width: 75px;
  position: absolute;
  top: 0;
  left: 0.8rem;
  text-transform: capitalize;
  white-space: normal;
  text-align: center
}

.dateTag strong {
  font-size: 20px;
  display: block;
}

.newsCol h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 0.8rem;
}

.newsCol h3 a {
  color: #242424;
}

.newsCol p {
  margin: 0 0 1rem;
}

.newsCol .btn-read {
  color: #E33CC7;
  /*text-transform: uppercase;*/
  font-weight: 700;
  text-decoration: underline;
  display: inline-block;
}

.newsCol .btn-read:hover,
.newsCol .btn-read:focus {
  color: #080015;
}

.newsPanel {
  background: #eeeeee40;
  padding: 1rem;
  border: 1px solid #eeeeee;
}

.newsPanel h4 {
  margin-bottom: 1rem;
}

.newsPanel ul li a {
  display: block;
  color: #060606;
  font-size: 13px;
  border-bottom: 1px dashed #dd551580;
  margin-bottom: 1rem;
  padding-bottom: 0.7rem;
}

.newsPanel ul li:last-child a {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.newsPanel ul li a:hover {
  border-bottom: 1px dashed #dd5515;
}

.newsPanel ul li a h5 {
  font-size: 15px;
  letter-spacing: 0;
}

.newsPanel li img {
  max-width: 55px;
  margin: 0 0.5rem 0 0;
  float: left;
  object-fit: cover;
  height: 45px;
}

/*-=-=-=-=-=-=-
Newsletter Css
-=-==-=-=-=-*/

.subscribeMain {
  background: url(./assets/cta-bg.jpg) no-repeat top center;
  background-size: cover;
  color: #fff;
}

.subscribeMain h2 {
  font-size: 40px;
  line-height: 1.5;
  color: #fff;
}

.subscribeMain h3 {
  color: #fff;
}

.subscribeMain form {
  width: 60%;
  margin: auto;
}

.subscribeMain .form-group {
  display: flex;
  align-items: center;
}

.subscribeMain .form-control {
  border: 2px solid #fff;
  background: none;
  color: #eee;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  height: 45px;
  padding-left: 1rem
}

.subscribeMain .form-control::-webkit-input-placeholder {
  color: #fff;
}

.subscribeMain .btn-primary {
  background: #fff;
  color: #000000;
  line-height: 30px;
  margin-left: 1rem
}

.subscribeMain .btn-primary:hover,
.subscribeMain .btn-primary:focus {
  background: #080015;
  color: #fff;
}

/*-=--=-=-=-=
Footer css
-=-=-=-=-=-*/

footer {
  padding: 3rem 0 2rem;
  background: #080015;
  color: #fff;
  /* position: absolute; */
  /* width: 100%; */
  /* bottom: 0; */
}

footer h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  /*text-transform: uppercase;*/
  margin: 3.5rem 0 1.5rem;
  text-align: center
}

footer .list-group {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
  justify-content: center;
  align-items: center;
}

footer .list-group-item {
  position: relative;
  display: block;
  padding: 0;
  background-color: transparent;
  border: 0;
  margin: 0;
  color: #fff;
}

footer .list-group-item:hover {
  color: #f45e16;
}

.footList .list-group-item i {
  color: #E33CC7;
  margin-right: 0.3rem
}

.footList .list-group-item,
.socialGroup .list-group-item {
  margin: 4px 0;
}

.socialGroup .list-group-item i {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  color: #fff;
  font-size: 35px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out
}

footer .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.socialGroup .list-group-item .fa-facebook {
  background: #3b5998;
}

.socialGroup .list-group-item .fa-twitter {
  background: #55acee;
}

.socialGroup .list-group-item .fa-youtube-play {
  background: #d42428
}

.socialGroup .list-group-item i:hover {
  opacity: 0.8;
}

footer hr {
  border-top: 1px solid rgba(255, 255, 255, .1);
  margin: 4rem 0 1rem
}

footer .bottomList {
  margin-top: 0;
  justify-content: flex-start;
}

.bottomList .list-group-item,
footer p {
  font-size: 13px;
  margin-bottom: 0
}

.bottomList .list-group-item {
  position: relative;
  padding: 0px 12px 0 0;
  margin-right: 12px;
}

.bottomList .list-group-item:last-child {
  margin-right: 0;
  padding-right: 0;
}

.bottomList .list-group-item:after {
  content: '';
  position: absolute;
  border-left: 1px solid #ffffff50;
  right: 0;
  /* adjust this */
  height: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  /* adjust this */
  background: #fff;
  width: 1px;
}

.bottomList .list-group-item:last-child:after {
  display: none
}

.bottomList .list-group-item a {
  color: #ffffff;
}

.TX--inner--Banner {
  text-align: center;
  height: auto;
  width: 100%;
  margin: 0 auto;
}

.TX--inner--Banner h1 {
  color: #fff;
  /* padding: 4em 0 1em 0; */
}

.eventsMain {
  border-bottom: 1px solid rgba(255, 255, 255, .1)
}

.loginForm form {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  box-shadow: 0 0 20px -15px rgba(0, 0, 0, .7);
  -webkit-box-shadow: 0 0 20px -15px rgba(0, 0, 0, .7);
  padding: 0 1.5rem 0 0;
  overflow: hidden;
}

.loginForm form a {
  color: #1a0a23;
  font-weight: 600;
  text-decoration: underline
}

.loginForm form .formTitle {
  background: url(./assets/event-bg.jpg) no-repeat top center;
  padding: 0 1.5rem;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;
}

.loginForm form .formTitle h3 {
  color: #fff;
  margin: 1.5rem 0;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 600;
}

.loginForm form .formTitle h3 span {
  display: block;
  font-size: 80%;
  font-weight: 400;
}

/*-==-=-=-=-=
About Page
-=-===-=-=-=*/

.aboutPage .media {
  /* border: 1px solid rgba(0, 0, 0, .05); */
  border-radius: 5px;
  box-shadow: 0 0 20px -15px rgba(0, 0, 0, .7);
  -webkit-box-shadow: 0 0 20px -15px rgba(0, 0, 0, .7);
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.aboutPage .media h5 {
  margin-bottom: 1rem;
}

.aboutPage .media h5 span {
  color: #E33CC7;
  display: block;
  margin-bottom: 0.3rem;
  /*text-transform: uppercase;*/
  font-size: 75%;
  font-weight: 700;
}

.aboutPage .card {
  border: 0;
  padding: 0;
  margin-top: 3rem;
  text-align: center;
}

.card {
  background-color: transparent;

}

.aboutPage .card-title {
  font-weight: 600;
  font-size: 18px
}

.aboutPage .card img {
  max-width: 70px;
  margin: auto;
}

/*-=-=-=-=-=-=
Support Page
-=-==-=-==-*/

.helpPage {
  padding: 5rem 0 4rem;
}

.helpPage h2 {
  font-size: 1rem;
  margin: 0 0 1rem;
  border-radius: 5px;
  display: inline-block;
  background: #E33CC7;
  color: #fff;
  text-align: center;
  padding: 0.8rem 3rem 0.8rem 1rem;
  font-weight: 600;
  position: relative;
}

.helpPage h2::before,
.helpPage h2::after {
  content: '';
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  top: 0;
  bottom: 0;
  position: absolute;
}

.helpPage h2::after {
  border-right: 26px solid #ffffff;
  border-left: 22px solid transparent;
  right: 0
}

.helpPage .accordion {
  margin-bottom: 2rem
}

.helpPage .card {
  border: 1px solid rgb(224 74 2 / 8%);
}

.helpPage .card-header {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.helpPage .accordion .show {
  border-top: 1px solid rgba(0, 0, 0, .05)
}

.helpPage .accordion button {
  padding: .75rem 2rem .75rem 1rem;
  position: relative;
  display: block;
  color: #1a0a23;
  font-weight: 600;
  margin: 0;
  width: 100%;
  text-align: left
}

.helpPage .accordion button:after,
.helpPage .accordion button:focus:after {
  position: absolute;
  right: 1rem;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  top: 0.5rem;
  color: #E33CC7;
  content: '\f107';
  font-family: 'FontAwesome';
  transform: rotate(0deg)
}

.helpPage .accordion button:hover,
.helpPage .accordion button:focus {
  box-shadow: none;
  outline: none;
  background: #f7f7f7;
  text-decoration: none;
}

.faqPage {
  background: #ffffff url(./assets/mark.png) no-repeat center right 3rem;
  padding: 5rem 0
}

.loginForm {
  background-color: #ffffff;
}

/*-=-==-=-
Ticket Page Css
=--=-=-==-=-=*/

.ticketPage .breadcrumb {
  background: #E33CC7;
  color: #fff;
  padding: 1rem 2rem;
  margin: 0;
  border-radius: 0;
  border: 0;
}

.ticketPage .breadcrumb button:hover,
.ticketPage .breadcrumb button:focus {
  border: 2px solid #fff;
  background: #fff;
  color: #1a0a23;
}

.ticketPage .breadcrumb h3 {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.5
}

.ticketPage .breadcrumb h3 small {
  font-size: 75%;
  display: block
}

.infoModal {
  z-index: 999999;
}

.infoModal .modal-dialog {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60%;
  height: 100%;
}

.infoModal .modal-header .modal-title {
  font-weight: 700;
}

.infoModal .modal-body {
  padding: 1.5rem
}

.infoModal .modal-body h3 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1.1rem
}

.infoModal .contentSec p {
  margin: 0;
  background: #f7f7f7;
  padding: 0.8rem;
  border: 1px solid rgba(0, 0, 0, .05)
}

.infoModal .contentSec p:nth-child(even) {
  background: #f2f2f2;
}

.infoModal .contentSec p span {
  display: inline-block;
  min-width: 90px;
  font-weight: 600
}

.eventCell {
  display: flex;
  text-decoration: none;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  /* background: hsla(0,30%,86.9%,.764705882352941); */
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  text-align: left;
  width: 100%;
}

.eventCell:hover {
  /* border-bottom: 1px solid #aaaaaa; */
  background: #fff;
}

.eventCell h3:hover {
  background: #eae2f3;
  border-radius: 10px;
  /*box-shadow: 0 0 40px -30px rgba(0, 0, 0, .4);
  -webkit-box-shadow: 0 0 40px -30px rgba(0, 0, 0, .4);*/
}

.eventCell .eventDate {
  font-weight: normal;
  /*margin-right: 0rem;*/
  display: flex;
  align-items: center;
  text-align: center;
}

.eventCell .eventDate span {
  font-size: 1.4rem;
  width: 67px;
}

.eventCell .eventDate span span {
  display: block;
  font-size: 85%;
  border-top: 1px solid #333333;
}

.eventCell .eventDate strong {
  /*background: #666666;*/
  /*padding: 1rem;*/
  font-weight: 600;
  /*color: #fff;*/
  /*margin-right: 1rem;*/
  /*min-width: 100px;*/
  display: inline-block;
  text-align: center;
  /*line-height: 40px;*/
  border-radius: 10px 10px 10px 10px;
}

.eventCell h3 {
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  width: 100vw;
}



.eventCell h3 p {
  margin: 0;
}

.eventCell h3 small {
  font-size: 70%;
  display: block;
  margin-top: 5px;
}

.eventCell .eventPrice {
  color: #1a0a23;
  font-size: 1.15rem;
  font-weight: 700;
}



/*  Pagination Css */

.pagination {
  border-radius: 0;
  justify-content: center;
  padding: 0;
  margin-top: 1rem;
}

.pagination>li {
  display: inline-block;
  padding-left: 0;
}

.pagination>li {
  list-style: none;
  border: 0;
  margin: 0 0.1rem
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 8px 15px;
  line-height: 1.42857143;
  text-decoration: none;
  background: #080015;
  color: #fff !important;
  border: 0;
  margin-left: 0px;
}

.pagination>li.active>a {
  color: #fff;
  background: #e33cc7;
}

/* Style the active class (and buttons on mouse-over) */
.pagination>li>a:hover {
  background-color: #d42428;
  color: white;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  display: none;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  display: none;
}

/* --------------404 Not Found-------------- */
#notfound {
  position: relative;
  height: 100vh;
  background-color: #000;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 60%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 240px;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  color: #E33CC7;
  /*text-transform: uppercase;*/
  letter-spacing: -40px;
  margin-left: -20px;
}

.notfound .notfound-404 h1>span {
  text-shadow: -8px 0px 0px #fff;
}

.notfound .notfound-404 h3 {
  font-family: 'Cabin', sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  /*text-transform: uppercase;*/
  color: #fff;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
}

.notfound h2 {
  font-family: 'Cabin', sans-serif;
  font-size: 20px;
  font-weight: 400;
  /*text-transform: uppercase;*/
  color: #fff;
  margin-top: 0px;
  margin-bottom: 25px;
}

.mapMain ul li {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, .05);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .05);
  padding: 1rem;
}

button.react-responsive-modal-closeButton {
  position: absolute;
  right: -15px;
  color: #fff;
  top: -15px;
  background: #fff;
  border-radius: 100px;
  -webkit-border-radius: 100px;
}

/*-=-=-=-=-=-=-=-=
Checkout Page Css
-=-=-=-==-=-==-*/


.cart .items {
  display: block;
  width: 100%;
  padding: 1.5em;
  border: 1px solid #f7f7f7;
}

.cart .items.odd {
  background: #fafafa;
}

.cart .items .infoWrap {
  display: table;
  width: 100%;
}

.cart .items .cartSection {
  display: table-cell;
  vertical-align: middle;
}

.cart .items .cartSection .itemNumber {
  color: #777;
  margin-bottom: .5em;
}

.cart .items .cartSection h3 {
  font-size: 1em;
  font-weight: 700;
  /*text-transform: uppercase;*/
}

.cart .items .quantity {
  font-weight: bold;
  color: #333;
}


.cart .items .qtyWrap,
.cart .items .prodTotal {
  text-align: right;
}

.cart .qtyWrap p,
.cart .prodTotal p {
  font-weight: bold;
  font-size: 1.25em;
  margin: 0
}

.cartSection p {
  margin: 0
}

.cart .items .cartSection .qty {
  width: 2em;
  text-align: center;
  font-size: 1em;
  padding: .25em;
  margin: 1em .5em 0 0;
}

.cart .items .cartSection .itemImg {
  width: 6em;
  float: left;
  display: inline;
  padding-right: 1em;
}

.removeWrap {
  text-align: right
}

.remove {
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  background: #cc0000;
  padding: 0;
  font-size: .75em;
  display: inline-block;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -ms-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.remove:hover {
  background: #1a0a23;
}

.promoCode {
  border: 0;
  float: none;
  width: auto;
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.promoCode .form-group {
  margin: 0;
  text-align: right
}

.promoCode label {
  margin: 0 1rem 0 0;
}

.promoCode input {
  width: 60%;
  display: inline-block;
  margin-right: 1rem;
}

.promoCode input:active,
.promoCode input:focus {
  outline: 0;
}

.subtotal {
  float: right;
  width: 35%;
}

.subtotal .totalRow {
  padding: 0.8rem;
  border: 1px solid #f7f7f7;
  text-align: right;
}

.subtotal .totalRow:last-child {
  border: 0;
  padding: 1rem 0
}

.subtotal .final {
  font-size: 1.25em;
  font-weight: bold;
}

.subtotal .totalRow span {
  display: inline-block;
  padding: 0 0 0 1em;
  text-align: right;
}

.subtotal .totalRow .label {
  font-size: .85em;
  text-transform: uppercase;
  color: #777;
}

.subtotal .totalRow .value {
  width: 35%;
}

/*-=-=-=-=-==-
Sell Tickets Page
-==-==-=-=-=-=*/

.sellPage button {
  padding: 1rem 2rem
}

.sellPage form,
.sellPage .stepDiv {
  box-shadow: 0 0 40px -20px rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0 0 40px -20px rgba(0, 0, 0, .1);
  padding: 2rem;
  border-radius: 10px;
}

.searchResult {
  text-align: left;
  background: #f8f8f870;
  padding: 0;
}

.searchResult .eventCell {
  padding: 1rem;
  margin-bottom: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #eee;
}

.loaderDiv {
  text-align: center;
  padding: 1rem 0;
}

.searchResult .eventCell:nth-child(even) {
  background: #f7f7f7;
}

.btn-secondary {
  color: #000000;
  background-color: #24242410;
  border-color: transparent;
  border-radius: 50px;
  -webkit-border-radius: 50px;
}

.sellPage .btn-secondary {
  padding: 0.5rem 1rem
}

.fileUpload {
  margin: 0;
  background: #add8e621;
  padding: 2rem;
  cursor: pointer;
  text-align: center;
}

.fileUpload #ticketUpload {
  opacity: 0;
  height: 0;
  width: 0;
  padding: 0
}

.fileUpload p {
  margin: 0
}

.fileUpload p strong {
  font-size: 1rem;
  display: block;
  margin-bottom: 1rem
}

.sellPage .stepDiv form {
  background: none;
  box-shadow: none;
  padding: 0;
  width: 100%;
}

.fileUpload svg {
  width: 100px;
  height: auto;
  margin-bottom: 1.5rem;
}

.stepDiv .form-control {
  height: 50px;
  font-size: 14px;
  padding: .35rem;
  background: #f8f8f850;
}

.stepDiv .form-group {
  position: relative;
}

.stepDiv .form-group span {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: auto;
  line-height: 50px;
  opacity: 0.3;
}

.stepDiv .priceFund {
  box-shadow: 2px 2px 15px -10px rgba(0, 0, 0, .3);
  -webkit-box-shadow: 2px 2px 15px -10px rgba(0, 0, 0, .3)
}

.stepDiv .priceFund li {
  padding: 1.5rem;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.stepDiv .priceFund li:last-child {
  border-bottom: 0;
}

.stepDiv .priceFund li p {
  margin: 0;
  font-weight: 600;
  font-size: 16px
}

.stepDiv .priceFund li p span {
  display: block;
  font-size: 80%;
  font-weight: normal
}

.stepDiv {
  position: relative;
}

.RCP {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100% !important;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  bottom: 0;
  right: 0;
}

.RCP svg {
  width: 150px;
  margin-top: 2rem;
}

.RCP .indicator {
  margin-top: -7.75rem;
  color: #fff !important;
  font-size: 14.5px;
}

.sampleTicket {
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  padding: 0.5rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, .1);
  cursor: pointer;
}

.sampleTicket .download {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .1);
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sampleTicket .download i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #f45e16;
  color: #fff;
  border-radius: 100px;
  font-size: 2rem;
  -webkit-border-radius: 100px;
}


.saleCol {
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  padding: 0.2rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, .1);
}

.saleCol .discount {
  position: absolute;
  top: 0;
  left: 0;
  background: #f45e16;
  color: #fff;
  display: inline-block;
  padding: 1rem 0;
  width: 70px;
  height: 70px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 0 100px 100px 100px;
  -webkit-border-radius: 0 100px 100px 100px;
}

.saleCol .discount small {
  display: block;
  font-weight: normal;
}

.saleCol .saleDesc {
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  justify-content: space-between;
}

.saleDesc p {
  margin: 0;
  color: #444444;
  font-weight: 700;
  font-size: 17px
}

.saleDesc p span {
  display: inline-block;
  font-weight: normal;
  font-size: 14px;
  /* text-decoration: line-through; */
  color: #000
}

.saleCol h3 {
  font-size: 18px;
  margin: 0;
}

.saleCol h3 small {
  display: block
}

.pdf-viewer {
  width: 600px;
  height: 700px;
}

.dropdown-menu {
  transform: translate(0px, 8px) !important;
  -webkit-transform: translate(0px, 8px) !important
}

.upcomingMain {
  background-color: #fcf6fe;
}

.sell-button:hover {
  background-color: #E33CC7;
  color: #fff !important;
  border: 1px solid #E33CC7;
}

div#scrollableDiv>.btn-primary:hover,
div#scrollableDiv>.btn-primary:focus,
div#scrollableDiv>.btn-primary:active {
  background: none;
}

.infinite--height {
  max-height: 300px;
  overflow-y: auto;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #cccccc;
}


/** 20-12-2023 **/
ul#sea-precheckout-qty-select * {
  all: unset;
}

ul#sea-precheckout-qty-select li {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, .05);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .05);
  padding: 0 !important;
  width: 38px;
  transition: .3s;
  border: solid 1px #d2d2d2;
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  height: 38px;
  margin: 0 8px 8px 0;
  min-height: 1px;
  float: left;
}

ul#sea-precheckout-qty-select .slick-prev,
ul#sea-precheckout-qty-select .slick-next {
  display: none !important;
}

ul#sea-precheckout-qty-select .sea-disabled {
  opacity: 0.5;
  pointer-events: none;
}

ul#sea-precheckout-qty-select li:hover {
  background: #29c142;
  border: 1px solid #29c142;
  color: #000;
  transition: .3s;
  color: #fff;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
}

div#tn-maps {
  padding-top: 16px;
}

ul#sea-precheckout-qty-select .sea-selected {
  background: #29c142;
  border: 1px solid #29c142;
  color: #000;
  transition: .3s;
  color: #fff;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
}

div#pre-checkout-price-text-ctn .pre-checkout-price-amt {
  font-size: 15px;
  font-weight: 700;
}

button#pre-checkout-price-cta {
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.5px;
}

div#pre-checkout-price-text-ctn .pre-checkout-price-amt {
  font-size: 15px;
  font-weight: 700;
}

button#pre-checkout-price-cta {
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.5px;
}

span#pre-checkout-price-amt:after {
  content: 'No hidden fees. Always.';
  color: #108a24;
  display: block;
  font-size: 14px;
  position: absolute;
  right: 0;
  width: 200px;
  top: 25px;
}

div#pre-checkout-price-text-ctn {
  padding-bottom: 30px;
}

div#precheckout-parent .pre-checkout-est-section-30 {
  float: left;
  width: 33%;
  text-align: center;
}

div#precheckout-parent .pre-checkout-est-section-20 {
  float: left;
  width: 25%;
  text-align: right;
}

.abc {
  padding-left: 15px;


}

span.abc[aria-label]::before {
  content: attr(aria-label);
  color: #333;
  font-size: 18px;
  font-family: Arial, sans-serif;
  font-weight: 400;
}

/** map section price **/

div#pre-checkout-est-section-calc span#pre-checkout-selected-qty-label {
  display: none;
}

div#pre-checkout-est-section-calc span {
  font-weight: 700;
}

div#pre-checkout-est-section-calc span#pre-checkout-selected-qty-label+span {
  display: none;
}

div#pre-checkout-est-section-calc span.venue-ticket-list-cta-amt-suffix {
  visibility: hidden;
  position: relative;
  width: fit-content;
}

div#pre-checkout-est-section-calc span.venue-ticket-list-cta-amt-suffix:after {
  content: 'each';
  color: #000;
  visibility: visible;
  width: 31px;
  display: inline-block;
  text-indent: -14px;
  font-weight: 500;
}

div#pre-checkout-price-text-ctn .vertical-align-middle.pre-checkout-est-section-50.sea-pre-checkout-sub-label {
  display: none;
}

.vertical-align-middle.pre-checkout-est-section-20.sea-pre-checkout-sub-amt {
  display: none;
}

#pre-checkout-est-section-calc:after {
  content: 'No hidden fees. Always.';
  color: #108a24;
  display: block;
  font-size: 14px;
  position: absolute;
  width: fit-content;
  top: 0;
  visibility: visible;
  right: 0;
  left: unset;
  font-weight: 700;
}

#pre-checkout-est-section-calc {
  position: relative;
}

div#pre-checkout-est-section-calc {
  width: 100%;
}

div#pre-checkout-price-text-ctn .vertical-align-middle.pre-checkout-est-section-30 {
  width: 100%;
  text-align: left;
}

div#moreDeliverycontent ul li::before {
  content: '\e80d';
  font-family: "cyprus";
  font-style: normal;
  font-weight: normal;
  /* speak: never; */
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 9px;
  top: 1px;
  position: relative;
}

div#moreDeliverycontent span.cm-star {
  display: none;
}

div#moreDeliverycontent ul li {
  margin-bottom: 10px;
  align-items: start;
}

/*End*/

.map-list-ctn {
  background-color: #fff;
}

div#pre-checkout-back-row {
  z-index: 999999;
  position: relative;
}



div#venue-map {
  position: absolute;
}

div#list-ctn {
  position: inherit;
}

div#venue-map-zoom-controls {
  display: block;
}

div#sea-inventory-filtersBtncnt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sea-quantity-slider {
  border-top: 1px #e3e3e3 solid;
  padding: 6px 0;
  margin-bottom: 10px;
  border-radius: 5px;
  border-bottom: 1px #e3e3e3 solid;
}

ul#sea-precheckout-qty-select li {
  margin-bottom: 0;
}

/** Abhishek NEW HOME PAGE CSS **/
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@media (min-width: 1380px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px !important;
  }
}


.navBarWrapper .navbar-light .navbar-nav .nav-link {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 34px;
  padding: 0;
  display: block;
}

header {
  background-color: transparent !important;
}

.navBarWrapper {
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navBarWrapper button#dropdown-basic {
  background: none;
  padding: 0;
}

a.logoWrapper.nav-link {
  float: left;
}

.navbar-light .navbar-nav .nav-link:after {
  position: absolute;
  left: 0;
  bottom: -1px;
  content: '';
  width: 0;
  height: 1px;
  background: #48b6cd;
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
}

.navbar-light .navbar-nav .nav-link:hover:after {
  width: 100%;
}

.logoWrapper img {
  max-width: 100px;
}

.rightSideNavItems {
  gap: 5px;
  padding: 20px 0;
}

.navBarWrapper .navbar-light .navbar-nav.rightSideNavItems .nav-link {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  padding: 16px 25px;
}

.navBarWrapper .navbar-light .navbar-nav.rightSideNavItems .nav-link:last-child {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}

.navBarWrapper .navbar-light .navbar-nav.rightSideNavItems .nav-link.active {
  background-color: #48B6CD;
}

div#responsive-navbar-nav {
  width: 60%;
  flex-basis: 60%;
  max-width: 60%;
  justify-content: space-between;
}

.navBarWrapper .navbar-light .navbar-nav.rightSideNavItems .nav-link:hover:after {
  content: unset;
}

.TX--inner--Banner {
  position: relative;
  background-image: url('/public/images/banner.jpg');
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-size: cover;
  width: 100%;
  background-position: top center;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 360px;
}

.TX--inner--Banner h1 {
  position: relative;
  z-index: 2;
  padding-top: 100px;
}

.calagory-main-section-main-wraper {
  position: relative;
  z-index: 2;
  padding-top: 6rem;
}

/**banner***/
.bannerWrapper:after,
.TX--inner--Banner:after {
  content: '';
  background: linear-gradient(180deg, rgba(227, 60, 199, 0.5) 0%, rgba(227, 60, 199, 0.2) 100%);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 200px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 1;
}

.bannerWrapper {
  max-height: 850px;
  height: 850px;
  position: relative;
  background-image: url('/public/images/banner.jpg');
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-size: cover;
  width: 100%;
  background-position: top center;
}

.bannerContent {
  position: relative;
  z-index: 3;
}

.bannerWrapper .container {
  padding-top: 100px;
  height: 100%;
}

.bannerWrapper .container .row {
  height: 100%;
}

.bannerContent h2 {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 120px;
  line-height: 120px;
  padding: 0;
  margin-bottom: 2.5rem;
  display: block;
  color: #fff;
  width: 100%;
  text-align: center;
}

.bannerContent h2 span.yellowClr {
  color: #FADB0E;
}

span.whiteShape {
  width: 40px;
  height: 86px;
  display: inline-block;
  background: #fff;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  position: relative;
  margin-left: -18px;
  margin-right: -16px;
}

span.pinkShape {
  width: 40px;
  height: 86px;
  display: inline-block;
  background: #E33CC7;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  position: relative;
  margin-right: -19px;
}

.bannerContent input#formText {
  height: 70px;
  border-radius: 0;
  background: none;
  border: 2px solid #fff;
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding: 10px 31px;
  max-width: 650px;
  width: 100%;
}

.bannerContent ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
}

.bannerContent ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
}

.bannerContent :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
}

.bannerContent :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
}

.bannerContent input#formText {
  height: 100px;
  border-radius: 0;
  background: none;
  border: 2px solid #fff;
  color: #fff;
}

.bannerContent button {
  font-optical-sizing: auto;
  padding: 0.5rem 2rem;
  background: #e33cc7;
  border-radius: 0;
  text-transform: uppercase;
  margin-left: 30px !important;
  color: var(--White, #FFF);
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.bannerContent button i {
  margin-left: 10px;
  transform: rotate(90deg);
}

.bannerContent .searchbtn img {
  width: 24px;
  margin-left: 13px;
}

.searchbtn.form-group {
  align-items: center;
  justify-content: center;
}

.bannerContent button {
  display: block;
  height: 100px;
}


/**banner end***/
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #e33cc7;
  border-color: #e33cc7;
}

/**slider***/
.eventSlider .slick-slider .slick-list {
  width: unset;
}

.eventSlider .slick-slide {
  padding: 0 1rem;
}

.eventSlider {
  padding: 230px 0 40px 0;
  background: none;
  width: 100%;
}

.eventSlider .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 16px;
  height: 16px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
}


.eventSlider .event--col img {
  min-height: auto;
  object-fit: cover;
  height: 330px;
}

.eventSlider li.slick-active button {
  background: #E33CC7;
  border-radius: 50px;
  border: 1px solid #E33CC7;
}

.eventSlider .slick-dots {
  bottom: -4px;
}

.slick-dots li button:before {
  font-size: 0;
  width: 16px;
  height: 16px;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}

.eventSlider .slick-dots li button {
  font-size: 0;
}

.eventSlider .event--col {
  position: relative;
}

.eventSlider .list-group {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 1) 100%);
  justify-content: flex-end;
  padding-bottom: 1.5rem;
  transition: all .5s ease-in-out;
}

.eventSlider .list-group-item {
  background: none;
  border: 0;
  padding: 0 1.5rem;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.eventSlider .list-group-item h4 {
  color: #ffffff;
  font-size: 18px;
  margin: .25rem 0 .5rem;
}

.eventSlider .list-group-item strong {
  margin-left: auto;
  font-size: 24px;
  flex: none;
}

.eventSlider .list-group-item+.list-group-item {
  margin-top: 0rem;
}

.eventSlider .list-group-item span {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.eventSlider .list-group-item img {
  max-width: 22px;
  min-height: none;
  height: auto;
  margin-right: 0.5rem;
}

.bottomBarrelWrap .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 16px;
  height: 16px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
}

.bottomBarrelWrap li.slick-active button {
  background: #E33CC7;
  border-radius: 50px;
  border: 1px solid #E33CC7;
}

.bottomBarrelWrap .slick-dots {
  bottom: -4px;
}

.bottomBarrelWrap .slick-dots li button:before {
  font-size: 0;
  width: 16px;
  height: 16px;
}

.bottomBarrelWrap .slick-dots li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}

.bottomBarrelWrap .slick-dots li button {
  font-size: 0;
}

.blogWrap .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 16px;
  height: 16px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
}

.blogWrap li.slick-active button {
  background: #E33CC7;
  border-radius: 50px;
  border: 1px solid #E33CC7;
}

.blogWrap .slick-dots {
  bottom: -4px;
}

.blogWrap .slick-dots li button:before {
  font-size: 0;
  width: 16px;
  height: 16px;
}

.blogWrap .slick-dots li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}

.blogWrap .slick-dots li button {
  font-size: 0;
}

img#bannerimage:focus-visible {
  outline: none;
}

.eventSlider :focus-visible {
  outline: none;
}

/** Event Section Start **/
.upcomingEvents .card-img-top {
  object-fit: cover;
  background: #fff;
  height: 200px;
  border-radius: 0;
}

.upcomingEvents {
  /* background: #0f0229; */
  padding: 20px 0 40px 0;
}

.eventWrapper.card .list-group-item {
  background: transparent;
}

.eventWrapper.card .card-title.h5 {
  font-optical-sizing: auto;
  margin: 0;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--White, #FFF);
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}

.eventWrapper.card .list-group-item {
  background: transparent;
  font-optical-sizing: auto;
  padding: 15px 0 0 0;
  color: var(--White, #FFF);
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.eventWrapper.card .list-group-item img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.card-title.h5 {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  color: var(--White, #FFF);
  font-size: 24px;
}

.eventWrapper.card .list-group-item.price {
  color: var(--White, #FFF);
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.upcomingEvents .row .col-md-3 {
  padding-bottom: 30px;
}

.eventWrapper.card .card-body {
  padding: 16px 0 0 0;
}

.upcomingEvents h2 {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
  line-height: 50px;
  padding: 0;
  display: block;
  color: #fff;
}

.eventsCards.row,
.seatsWrap {
  position: relative;
}

.upcomingEvents h2 .pink {
  color: #E33CC7;
}

.seatsWrap .container {
  position: relative;
  z-index: 3;
}

.headingwrap {
  margin-bottom: 55px;
}

.searchwrap img {
  width: 30px;
  height: 30px;
}

.searchwrap img {
  width: 30px;
  height: 30px;
}

.searchwrap input.form-control {
  background: transparent !important;
  border: none;
  padding: 0;
  max-width: calc(100% - 50px) !important;
  height: 100%;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  color: #fff;
  font-size: 24px;
}

.searchwrap .input-group {
  border: 1px solid rgb(255 255 255 / 30%);
  height: 80px;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 !important;
}

.searchwrap span.input-group-text {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  color: #B5B5B8;
  font-size: 24px;
  background: none;
  border: none;
  padding: 0 10px 0 0;
}

.seatsWrap {
  padding: 40px 0 0 0;
  /* background: #0f0229; */
  color: #fff;
}

.seatsWrap .headingwrap .col-sm-12 {
  flex-direction: column;
}

.seatsWrap .headingwrap h2 {
  padding: 0;
  display: block;
  margin-bottom: 23px;
  color: var(--White, #FFF);
  font-family: "Bebas Neue", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
}

.seatsWrap .headingwrap p {
  font-optical-sizing: auto;
  width: 60%;
  margin-bottom: 50px;
  color: #B5B5B8;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.sea-highlighted-row,
.sea-highlighted-row .venue-ticket-list-mark-html-js .cm-star-4::before,
.sea-highlighted-row .sea-superhighlight-text {
  animation: none;
}

.seatsWrap .headingwrap p button {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.seatsWrap .headingwrap p button:hover,
.seatsWrap .headingwrap p button:focus {
  outline: none;
}

.seatsWrap .headingwrap .pink {
  color: #E33CC7;
  text-decoration-line: underline;
}

.seatsWrap .headingwrap {
  margin-bottom: 0;
}

.seatsWrap .headingwrap {
  margin-bottom: 0;
}

.seatsWrap .itemsWrap .mainwrapper {
  background: #130e21;
  flex-direction: column;
  color: #fff;
  padding: 30px;
  width: 100%;
  display: flex;
}

.seatsWrap .itemsWrap .mainwrapper a {
  display: block;
}

.seatsWrap .itemsWrap .headingLinkWrap h2 {
  font-optical-sizing: auto;
  color: var(--White, #FFF);
  font-family: "Rubik", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
}

.seatsWrap button.arrowIcon {
  width: 50px;
  height: 50px;
  border: 1px solid #c836b0;
  border-radius: 50px;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.seatsWrap button.arrowIcon:hover {
  background: #c836b0;
}


.seatsWrap button.arrowIcon img {
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -ms-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  margin: auto;
}

.seatsWrap button.arrowIcon:hover img {
  filter: brightness(15.5);
  -webkit-filter: brightness(15.5);
}

.seatsWrap .headingLinkWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 27px;
}

.seatsWrap .mainwrapper {
  position: relative;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border: 3px solid transparent;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%) 0;
}

.seatsWrap .mainwrapper:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border: 3px solid transparent;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%) 1;
}

.nearCTA {
  background: #16142a;
  padding: 100px 0;
  position: relative;
}

.nearCTA .headingwrap {
  margin-bottom: 0;
}

.nearCTA .headingwrap .col-sm-12 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.nearCTA .headingwrap .col-sm-12 h2 {
  padding: 0;
  display: block;
  margin-bottom: 0;
  color: var(--White, #FFF);
  font-family: "Bebas Neue", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 90px;
}

.nearCTA ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #B5B5B8;
}

.nearCTA ::-moz-placeholder {
  /* Firefox 19+ */
  color: #B5B5B8;
}

.nearCTA :-ms-input-placeholder {
  /* IE 10+ */
  color: #B5B5B8;
}

.nearCTA :-moz-placeholder {
  /* Firefox 18- */
  color: #B5B5B8;
}

.nearCTA .searchwrap input.form-control {
  color: #B5B5B8;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
}

.nearCTA button {
  width: -moz-fit-content;
  width: fit-content;
  font-optical-sizing: auto;
  border-radius: 0;
  margin: 0 auto;
  color: var(--White, #FFF);
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  background: #E33CC7;
  padding: 33px 50px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.nearCTA .searchwrap .input-group {
  height: 100px;
}

.nearCTA button img {
  margin-left: 30px;
  width: 18px;
  height: 18px;
  filter: brightness(5.5);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.searchwrap:hover img {
  filter: brightness(1);
  -webkit-filter: brightness(1);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.nearCTA button:hover {
  background: #fff;
  color: #d73cc9;
}

.bannerContent .searchbtn button:hover,
.bannerContent .searchbtn button:active {
  background-color: #000 !important;
  color: #fff !important;
}

.bannerContent .searchbtn button:focus,
.bannerContent .searchbtn :focus-visible {
  outline: none;
}

.eventSlider {
  position: relative;
}

.eventSlider .slick-slider {
  z-index: 3;
}

.nearCTA:after {
  content: '';
  background: url('/public/images/yellow-shape.svg');
  width: 337px;
  height: 338px;
  flex-shrink: 0;
  display: block;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  right: 0;
  background-position: right bottom;
}

.nearCTA:before {
  content: '';
  background: url('/public/images/blue-shape.svg');
  width: 337px;
  height: 338px;
  flex-shrink: 0;
  display: block;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.nearCTA .searchwrap {
  position: relative;
}

.nearCTA .searchwrap:after {
  content: '';
  background: url('/public/images/brown-shape.svg');
  width: 105px;
  height: 102px;
  flex-shrink: 0;
  display: block;
  background-repeat: no-repeat;
  position: absolute;
  top: -160px;
  right: -63px;
}

.nearCTA .searchwrap:before {
  content: '';
  background: url('/public/images/black-shape.svg');
  width: 105px;
  height: 102px;
  flex-shrink: 0;
  display: block;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: -105px;
}

.bottomBarrelWrap .slick-list {
  width: 100%;
}

/* the slides */
.bottomBarrelWrap .slick-track {
  gap: 15px;
  display: flex;
}

.bottomBarrelWrap .slick-slide {
  background: #16142A;
  color: var(--White, #FFF);
  padding: 40px 20px;
  position: relative;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border: 3px solid transparent;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%) 0
}

.bottomBarrelWrap .slick-slide:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border: 3px solid transparent;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%) 1;
}

.bottomBarrelWrap h4 {
  color: var(--White, #FFF);
  font-family: "Rubik", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}

.bottomBarrelWrap p {
  color: #B5B5B8;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 20px;
}

.bottomBarrelWrap .slick-slide button,
.bottomBarrelWrap .slick-slide button a {
  color: #E33CC7;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-decoration-line: underline;
  padding: 0;
}

.bottomBarrelWrap .slick-slide .imgwrapper {
  width: 100px;
  height: 100px;
  position: relative;
  margin-bottom: 30px;
}

.bottomBarrelWrap .slick-slide .imgwrapper img {
  width: 100% !important;
  height: 100%;
  min-height: unset !important;
  object-fit: contain;
}

.bottomBarrelWrap {
  position: relative;
  /* background: #0A001A; */
  padding: 170px 0 150px 0;
}

.bottomBarrelWrap .slick-track {
  display: flex !important;
}

.bottomBarrelWrap .slick-slide {
  height: inherit !important;
}

.bottomBarrelWrap .headingwrap>div {
  flex-direction: column;
  align-items: flex-start !important;
}

.bottomBarrelWrap .headingwrap h2 {
  color: var(--White, #FFF);
  font-family: "Bebas Neue", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  /* 116.667% */
  width: 100%;
}

.bottomBarrelWrap .headingwrap h2+p {
  color: #B5B5B8;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
  margin: 0;
}

.bottomBarrelWrap .slick-slide {
  margin-top: 55px;
}

.bottomBarrelWrap button.slick-arrow.slick-next {
  right: 0px !important;
}

.bottomBarrelWrap button.slick-arrow.slick-prev,
.bottomBarrelWrap button.slick-arrow.slick-next {
  position: absolute;
  top: -31px;
  right: 80px;
  left: unset;
  width: 60px;
  height: 60px;
  border: 1px solid #d73cc9;
  border-radius: 50%;
  display: block !important;
  z-index: 10;
}

.bottomBarrelWrap .slick-next:before {
  content: '' !important;
  background: url('/public/images/next.svg');
  width: 16px;
  height: 16px;
  background-size: contain;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.bottomBarrelWrap button.slick-arrow.slick-prev.slick-disabled {
  opacity: 0.2 !important;
  pointer-events: none;
}

.bottomBarrelWrap button.slick-arrow.slick-next.slick-disabled {
  opacity: 0.2 !important;
  pointer-events: none;
}

.bottomBarrelWrap button.slick-arrow.slick-prev:before {
  content: '' !important;
  background: url('/public/images/prev.svg');
  width: 16px;
  height: 16px;
  background-size: contain;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.bottomBarrelWrap button.slick-arrow.slick-prev:hover,
.bottomBarrelWrap button.slick-arrow.slick-next:hover {
  background: #d73cc9;
  border-color: #d73cc9;
}

.bottomBarrelWrap button.slick-arrow.slick-prev:hover:before,
.bottomBarrelWrap button.slick-arrow.slick-next:hover:before {
  filter: brightness(100);
  -webkit-filter: brightness(100);
}

.comeOnFolksCTA {
  padding: 123px 0;
  background: url('/public/images/guitarists-with-colorful-lighting-stage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.comeOnFolksCTA h2 {
  color: var(--White, #FFF);
  font-family: "Bebas Neue", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  /* 116.667% */
  margin-bottom: 30px;
}

.comeOnFolksCTA button {
  display: inline-flex;
  padding: 25px 35px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background: var(--White, #FFF);
  color: #000;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  /*141.667%*/
  border-radius: 0;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
}

.comeOnFolksCTA button svg path {
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  fill: #000;
}

.comeOnFolksCTA button:hover,
.comeOnFolksCTA button:active {
  background: #d73cc9;
  border-color: #d73cc9;
}

.comeOnFolksCTA button:hover svg path,
.comeOnFolksCTA button:active svg path {
  fill: #fff;
}

.comeOnFolksCTA .headingwrap>div {
  flex-direction: column;
}

.blogWrap .slick-list {
  width: 100%;
}

/* the slides */
.blogWrap .slick-track {
  gap: 15px;
  display: flex;
}

.blogWrap .slick-slide {
  background: #16142A;
  color: var(--White, #FFF);
  padding: 40px 20px;
  position: relative;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border: 3px solid transparent;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%) 0
}

.blogWrap .slick-slide:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  border: 3px solid transparent;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%) 1;
}

.blogWrap h4 {
  color: var(--White, #FFF);
  font-family: "Rubik", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}

.blogWrap p {
  color: #B5B5B8;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
  margin-bottom: 20px;
}

.blogWrap .slick-slide button {
  color: #E33CC7;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 155.556% */
  text-decoration-line: underline;
  padding: 0;
}

.blogWrap .slick-slide .imgwrapper {
  width: 100%;
  height: 300px;
  position: relative;
  margin-bottom: 30px;
}

.blogWrap .slick-slide .imgwrapper img {
  width: 100% !important;
  height: 100%;
  min-height: unset !important;
  object-fit: cover;
  object-position: center;
}

.blogWrap {
  position: relative;
  /* background: #0A001A; */
  padding: 170px 0 0;
}

.blogWrap .slick-track {
  display: flex !important;
}

.blogWrap .slick-slide {
  height: inherit !important;
}

.blogWrap .headingwrap>div {
  flex-direction: column;
  align-items: flex-start !important;
}

.blogWrap .headingwrap h2 {
  color: var(--White, #FFF);
  font-family: "Bebas Neue", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  /* 116.667% */
  width: 100%;
}

.blogWrap .headingwrap h2+p {
  color: #B5B5B8;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
  margin: 0;
}

.blogWrap .slick-slide {
  margin-top: 40px;
}

.blogWrap button.slick-arrow.slick-next {
  right: 0px !important;
}

.blogWrap button.slick-arrow.slick-prev,
.blogWrap button.slick-arrow.slick-next {
  position: absolute;
  top: -31px;
  right: 80px;
  left: unset;
  width: 60px;
  height: 60px;
  border: 1px solid #d73cc9;
  border-radius: 50%;
  display: block !important;
  z-index: 10;
}

.blogWrap .slick-next:before {
  content: '' !important;
  background: url('/public/images/next.svg');
  width: 16px;
  height: 16px;
  background-size: contain;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.blogWrap button.slick-arrow.slick-prev.slick-disabled {
  opacity: 0.2 !important;
  pointer-events: none;
}

.blogWrap button.slick-arrow.slick-next.slick-disabled {
  opacity: 0.2 !important;
  pointer-events: none;
}

.blogWrap button.slick-arrow.slick-prev:before {
  content: '' !important;
  background: url('/public/images/prev.svg');
  width: 16px;
  height: 16px;
  background-size: contain;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.blogWrap button.slick-arrow.slick-prev:hover,
.blogWrap button.slick-arrow.slick-next:hover {
  background: #d73cc9;
  border-color: #d73cc9;
}

.blogWrap button.slick-arrow.slick-prev:hover:before,
.blogWrap button.slick-arrow.slick-next:hover:before {
  filter: brightness(100);
  -webkit-filter: brightness(100);
}

footer.footerWrap {
  background: url('/public/images/footerbtm.jpg');
  position: relative;
  padding: 8rem 0 2rem;
}

footer.footerWrap:before {
  content: "";
  background: linear-gradient(90deg, rgba(9, 8, 17, 1) 0%, rgba(22, 70, 80, 0.9) 100%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.navbar {
  padding: 0;
  background: none;
}

ul.socialGroup.list-group a {
  width: 44px;
  height: 44px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: normal;
  padding: 0;
  box-sizing: border-box;
  max-height: 44px;
  max-width: 44px;
  min-height: 44px;
  margin: 0;
}

ul.socialGroup.list-group a img {
  height: auto;
  max-width: 20px;
}

ul.socialGroup.list-group {
  gap: 12.5px;
  margin: 50px 0 72px 0;
  justify-content: start;
}

ul.socialGroup.list-group a:hover {
  background: #e33cc0;
}

ul.socialGroup a:hover img {
  filter: invert(1);
}

ul.bottomList.list-groupva {
  color: var(--White, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

footer .bottomList {
  margin-top: 0;
  justify-content: flex-end;
}

ul.bottomList.list-groupva {
  color: var(--White, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

footer.footerWrap .copyright {
  border-top: 1px solid rgba(255, 255, 255, .2);
  margin-top: 80px;
  padding-top: 20px;
  position: relative;
}

footer.footerWrap .copyright::before {
  position: absolute;
  top: -62px;
  right: 50px;
  content: '';
  background: url('../public/images/Ellipse.png') no-repeat top left;
  background-size: cover;
  width: 62px;
  height: 62px;
}

.footerWrap .copyright p,
.footerWrap .copyright .bottomList .list-group-item {
  font-size: 16px;
}

.footerWrap form {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  margin-top: 1rem;
}

.footerWrap form .input-group {
  flex: 1 1;
}

.footerWrap form input {
  height: 55px;
  border: 0;
  border-radius: 0;
}

.footerWrap form button {
  background: #E33CC7;
  padding: 0;
  width: 55px;
  font-size: 18px;
  height: 55px;
  min-width: 55px;
  border-radius: 0;
  margin: 0;
}

.footerWrap form button svg {
  fill: #ffffff;
  font-size: 28px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

ul.footList.list-group a {
  color: var(--White, #FFF);
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

ul.footList.list-group {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.footerWrap ul.footList.list-group h4 {
  color: var(--Color-Plate-06, var(--White, #FFF));
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 30px;
  margin-left: 0;
}

/** Abhishek css End **/

.abc {
  visibility: visible;
  resize: none;
  overflow: hidden;
  width: 100% !important;
  background: #fff;
  margin-bottom: 0px;
  display: block;
  padding-top: 10px;
}

.mapMain ul li {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, .05);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .05);
  padding: 0 !important;
}

.ticket-text {
  display: flex;
  justify-content: center;
}

.section-event-banner h1 {
  padding: 0;
  text-align: center;
  font-size: 25px;
  margin-right: 71px;
}

.section-event-banner {
  height: 220px;
}

.text-center-sec {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.event-text .event-text-data span span {
  display: block;
  /* font-size: 85%; */
  font-size: .85rem;
  /* border-bottom: 1px solid #333333; */
  padding-bottom: 0.8rem !important;
  margin-top: 0.4rem;
  border-top: none !important;
}

span.tickets-button-wrap {
  display: flex;
  flex-direction: column;
}

.tickets-available-text {
  text-transform: capitalize !important;
  text-align: center;
  font-weight: 400;
}

.text-boder-date {
  border-top: 1px solid #333333 !important;
}

.date-time-boder hr {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  /* border: 0; */
  border-top: 1px solid rgb(0 0 0 / 36%);
}

span.vanue-name-wrap {
  padding-left: 10px;
}

.svg-pdf-swal svg {
  width: 26px;
  position: absolute;
  top: 15px;
  right: 15px;
}

/* .swal-overlay--show-modal .swal-modal{
  padding-top: 60px;
} */
.aount-swal {
  font-weight: 600;
}

.ticket-search-bar .form-control {
  width: 100%;
  margin: 0 auto;
  /* z-index: 9999; */
}

.ticket-search-bar {
  /* margin-left: 90px; */
  margin-top: 5px;
  margin: 0 auto;
  width: 100%;
  max-width: 540px;
}

.tagMain {
  background-color: #fcf6fe;
}

.eventCell .home-page-result span span {
  border-top: 0px;
}

.home-page-result .text-boder-date {
  border-top: 1px solid #000 !important;
}

.circle-one-border span {
  border: 1px solid;
  border-radius: 50px;
  padding: 1px;
  width: 29px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-one-border {
  display: flex;
}

.tickets-p {
  font-size: 13px;
  margin-top: 15px;
  font-weight: 600;
  margin-left: -25px;
}

.email-rciept {
  display: flex;
  margin-left: 42px;
  margin-top: 30px;
}

.email-rciept span {
  font-size: 13px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-rciept input[type="text"] {
  width: 72%;
  padding-left: 6px;
  height: 40px;
  border-radius: 3px;
}

.payments-methed {
  display: flex;
  margin-left: 0;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
}

.payments-methed span {
  font-size: 13px;
  margin-right: 10px;
  margin-top: 6px;
}

.delivery-method {
  margin-left: 0;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  text-align: left;
}

.delivery-heading {
  margin-right: 10px;
  font-weight: 600;
  width: 440px;
}

/* .border-dotted{
  border-left: 1px dashed #333;
} */
.subtitile-ticket {
  display: flex;
  justify-content: space-between;
}

p.total-ticket-by {
  border-top: 1px solid #333;
  text-align: end;
  margin-top: 10px;
  padding-top: 10px;
  font-weight: 700;
}

.ticket-by-data {
  margin-top: 30px;
  margin-left: 42px;
  /* margin-right: 60px; */
  font-size: 14px;
}

.subtitle {
  font-weight: 700;
}

p.term-paypal {
  font-size: 12px;
  text-align: center;
}

p.order-total {
  font-size: 14px;
  text-align: center;
}

.swal-modal {
  width: 700px;
  height: 400px;
}

.paypal-buttons {
  margin-bottom: 20px;
}

.card-fields-container {
  max-width: 100%;
}

.searchResultticket {
  margin-top: 50px;
  font-size: 24px;
}

/* .ticket-search-bar button{
  z-index: 9999;
} */
/* .slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
} */

.pre-checkout-price-ctn button {
  background-color: #29c142;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 800;
}

.sea-quantity-container legend {
  font-size: 1rem;
}

.sea-quantity-items .sea-disabled {
  opacity: 0.5;
}

.searchbtn button:hover {
  background-color: #fff;
  color: #333;
}

.ticket-search-bar button:hover {
  background-color: #fff;
  color: #333;
}

.slick-next {
  right: 100px;
  width: 75px;
  height: 75px;
}

.slick-prev {
  left: 100px;
  width: 75px;
  height: 75px;
  z-index: 111;
}

.slick-next:before,
.slick-prev:before {
  font-size: 50px;
  color: #ffffff;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 400px;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  border-radius: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #f8ab86;
  color: black;
}

.flip-card-front>*,
.flip-card-back * {
  display: inline-block;
  margin: 10px 0;
}

/* Style the back side */

.flip-card-back>p {
  font-size: 14px;
}

.flip-card-back {
  background-color: black;
  color: white;
  transform: rotateY(180deg);
}

div.flip-card-front>h5 {
  font-weight: 500;
  font-size: 1.5rem;
}

.FlipCardSection {
  box-sizing: border-box;
  width: 100%;
  padding: 60px 5px;
  margin: auto;
  background-color: #fff;

}

.flip-card-title {
  margin-top: 15%;
  display: inline-block;

}

.flip-card-final {
  margin-top: 100%;
  display: inline-block;

}

.slick-list {
  width: 70%;
  margin: auto;
}

.eventCell {
  background-color: transparent !important;
}

@media only screen and (max-width: 600px) {

  .pre-checkout-parent,
  .sea-filterCard-parent {
    padding-right: 23px !important;
  }

  .eventCell {
    padding-right: 5px;
  }

  .searchResultEvent h2 {
    font-size: 20px;
  }

  .searchResultEvent h2 span {
    display: block;
  }

  .purchase-date {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ticket-puchased-main-wrap {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ticket-by-data {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .payments-methed {
    margin-left: 0;
  }

  .eventCell .eventDate span {
    width: 60px;
  }

  .tickets-button-wrap button {
    font-size: 8px;
    padding: 6px 8px;
    width: fit-content;
    margin: 0 0 0 auto;
  }

  #scrollableDiv span {
    font-size: 12px;
    margin-bottom: 0px;
  }

  .ticket-search-bar .form-control {
    z-index: 0;
  }

  .ticket-search-bar button {
    z-index: 0;
  }

  .searchResultEvent {
    padding: 2rem 1.2rem;
  }

  .eventCell h3 {
    width: 100%;
  }

  .eventCell h3 {
    font-size: 0.8rem;
  }

  .eventCell h3 small {
    font-size: 79%;
    color: #444
  }

  .flip-card {
    width: 260px;
    height: 400px;
  }

  .slick-next {
    right: 0px;
    width: 75px;
    height: 75px;
  }

  .slick-prev {
    left: 0px;
    width: 75px;
    height: 75px;
  }
}

.countertime {
  text-align: end;
}

.countertime p {
  margin-bottom: 0;
  color: #a80011;
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  font: italic 10px 'Arial Black', Gadget, sans-serif;
  z-index: 1000;
  padding-bottom: 2px;
  margin-bottom: 0;
  display: block;
}

.countertime .displayedTime span {
  color: #a80011;
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  font: normal 18px 'Arial', sans-serif;
  z-index: 1000;
  font-weight: bold;
}

.count {
  color: #a80011 !important;
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff !important;
  font: normal 18px 'Arial', sans-serif !important;
  z-index: 1000 !important;
  font-weight: bold !important;
}

.siteLogo img {
  width: 90px;
  filter: drop-shadow(2px 4px 6px black);
}

.purchase-date {
  text-align: left;
  /* margin-left: 60px;
  margin-right: 60px; */
  margin-top: 40px;
}

.purchased-heading-wrap {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}

.purchased-heading-wrap h2 {
  font-size: 22px;
}

.Receipt-purchase {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  /* flex-direction: row-reverse; */
  /* border-top: 1px solid; */
}

.Receipt-purchase h3 {
  font-size: 18px;
  color: #E33CC7;
  padding-top: 30px;
}

.pdf-text {
  font-weight: 700;
  color: #E33CC7;
  cursor: pointer;
}

.ticket-purchased-details-p {
  display: flex;
  justify-content: space-between;
}

.ticket-puchased-main-wrap {
  /* margin-left: 60px;
  margin-right: 60px; */
  margin-top: 24px;
}

.slick-slide {
  margin: 32px 0px;
}

.download-pdf-eticket {
  display: flex;
  align-items: baseline;
}

.download-pdf-eticket p {
  margin-right: 15px;
  font-weight: 700;
  /* color: #E33CC7; */
}

.download-pdf-eticket .fa-file-pdf-o {
  font-weight: 700;
  font-size: 24px;
  /* color: #E33CC7; */
}

@media only screen and (max-width: 768px) {
  .date-event-list {
    font-size: 14px;
  }
}

.date-catagory {
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .main-search-result-wraper {
    position: initial !important;
  }

  .search-result-main-wraper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .upcomingMain .eventDesc .btn-primary {
    font-weight: 600;
    text-transform: capitalize;
    padding: 0.5rem 0.6rem;
    font-size: 13px;
  }

  .venue-map {
    top: 100px !important;
  }

  .date-catagory {
    font-size: 14px;
  }

  .event-details {
    font-size: 14px !important;
  }

  .eventCell .eventDate span span {
    font-size: 60%;
  }

  .ticket-search-bar {
    margin-left: 0;
  }

  .ticket-search-bar .form-control {
    width: 100%;
  }

  .swal-modal {
    width: 95%;
  }

  .paypal-buttons {
    min-width: 220px !important;
  }

  section {
    padding: 2rem 1.3rem;

  }

  .signup-main {
    display: flex;
    justify-content: end;
    margin-top: 16px;
  }

  .map-ctn {
    height: inherit;
    margin-top: -80px;
    margin-bottom: -220px;
  }

  .list-ctn {
    top: 70px !important;
  }

  .list-inner-ctn {
    height: 300px !important;
  }

  .ticket-purchased-details-p {
    font-size: 12px;
  }

  .siteLogo img {
    width: 90px;
  }

  h1 {
    font-size: 1.8rem;
  }

  .carousel-caption form {
    width: 90%;
  }

  .form-control::placeholder {
    font-size: 13px;
  }

  /* .searchbtn .form-control {
    height: 35px;
    font-size: 13px;
  } */
  .searchbtn .form-control::placeholder {
    font-size: 16px;
  }

  .searchbtn .form-control {
    font-size: 16px;
  }

  /* 
  .searchbtn button {
    font-size: 14px;
  } */

  .carousel-caption .form-group i {
    left: 0.6rem;
    top: 0.6rem;
  }

  .searchResultticket {
    margin-top: 18px;
    font-size: 20px;
  }

  .ticket-search-bar {
    margin-top: 0px;
  }

}

.total-ticket-price {
  font-weight: 700;
}

.subtitile-ticket.total-ticket-by {
  border-top: 1px solid;
  margin-top: 20px;
  padding-bottom: 15px;
  padding-top: 20px;
}

span.text-boder-date {
  text-transform: capitalize;
}

.result-nan {
  height: 50vh;
}

.accordion-content .react-autosuggest__container input {
  width: 100%;
  max-width: 400px;
  height: 40px;
  border: 1px solid #cdcbcb;
  padding-left: 12px;
}

.aboutPage,
.event-page-section-second-wraper,
.eventSingle {
  background: #ffffff;
}

.homePage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: url(../public/images/body-bg.png) no-repeat top center;
  background-size: cover;
  overflow-x: hidden;
}

footer {
  margin-top: auto;
}

.eventList>img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.search--location {
  display: flex;
  align-items: center;
}

.accordion-title {
  display: flex;
  border-bottom: 1px solid #3333333d;
  padding-bottom: 10px;
  /* margin-bottom: 10px; */
  background: #dcdcdc4d;
  border: 1px solid #33333324;
  align-content: center;
  align-items: center;
  padding-left: 10px;
  justify-content: space-between;
  padding: 9px;
}

.active-accordian {
  margin-left: 18px;
  font-size: 20px;
  cursor: pointer;
}

.active-accordian svg {
  fill: #ffffff
}

.location--input {
  display: flex;
  border: 1px solid #ffffff20;
  padding: 1.2rem;
  margin-top: 0.5rem;
}

.location--input .react-autosuggest__container {
  flex: 1 1
}

.location--input .react-autosuggest__container input {
  height: 50px;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border: 0;
  border-radius: 0;
}

.location--input .btn-primary {
  border-radius: 0;
  background: #E33CC7;
  height: 50px;
  border: 0;
}

.location--input .btn-primary:hover,
.location--input .btn-primary:focus {
  background: #0A001A;
}

.location--input .react-autosuggest__suggestions-container {
  background: #ffffff;
}

.accordion-content {
  /* background: #f0f0f0; */
  padding: 21px;
  border-radius: 10px;
}

.nobr {
  white-space: nowrap;
}

.bannerMain {
  clip-path: polygon(0 0,
      /* left top */
      100% 0,
      /* right top */
      100% 100%,
      /* right bottom */
      70% 90%,
      30% 90%,
      0% 100%
      /* left bottom */
    );
}

#bannerimage {
  min-height: 300px;
}

.bannerMainContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: unset;

}

.upcoming-event-wrap h3 span strong {
  text-transform: capitalize;
}

.upcoming-event-wrap h3 span {
  font-weight: normal !important;
  text-transform: capitalize;
}

.sea-feedback-enabled #sea-feedback-btn {
  display: none;
}

.venue-ticket-list-row {
  background-color: transparent;
}

.eventDesc p {
  text-transform: capitalize;
}

.loder-event-wrap {
  margin: 0 auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  flex-direction: column;
}

.sea-precheckout-fees-parent {
  display: none;
}

svg.venue-map-svg {
  cursor: pointer;
}

.form-event-search .d-flex.searchbtn.form-group {
  width: 58%;
  margin: 0 auto;
  margin-top: 20px;
}

.top-nav-sub-cat .navbar-light .navbar-nav .nav-link {
  color: #333 !important;
}

h2.cate-flex {
  display: flex;
}

.date-cate-mm {
  font-size: 15px;
  font-weight: 400;
  margin-top: 11px;

}

.date-time-cate {
  font-size: 19px;
}

.date-cate {
  margin-top: 5px;
}

.cate-event-name {
  font-size: 1.3rem;
  font-weight: 600;
}

.cate-text-venue {
  font-size: 16px;
}

.cate-event-section-wrape.col-lg-8.col-7 {
  width: 800px;
}

.cate-ticket-section {
  margin: 0 auto;
  text-align: center;
}

.tickets-available-text {
  font-size: 14px;
}

.top-nav-sub-cat {
  padding-top: 16px;
  padding-bottom: 10px;
  background-color: #fff;
}

.top-nav-sub-cat button {
  padding: 0px;
  border: 0px;
  outline: 0px;
  background-color: transparent;
  font-weight: 500;
}

.top-nav-sub-cat .navbar {
  background-color: transparent !important;
}

.top-nav-sub-cat nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  border-bottom: 1px solid #e6e2e2;
  padding-bottom: 11px;
}

.top-nav-sub-cat .navbar-light .navbar-nav .nav-link {
  margin-left: 0px;
  margin-right: 1.7rem;
}

.top-nav-sub-cat button:hover {
  color: #E33CC7;
}

.top-nav-sub-cat .navbar-light .navbar-nav .nav-link:hover {
  color: #E33CC7;
}

.section-catagory-text-section {
  padding-left: 27px;
  padding-top: 20px;
  background-color: #fff;
}

.section-catagory-text-section h3 {
  font-size: 1.3rem;
  text-transform: capitalize;
}

.section-catagory-text-section h2 {
  font-size: 1.3rem;
  text-transform: capitalize;
}

.event-details {
  font-size: 19px;
  line-height: 1.6;
}

@media only screen and (max-width: 380px) {
  .event-details {
    font-size: 12px !important;
  }
}

.top-nav-sub-cat .navbar-toggler {
  position: initial !important;
  margin: auto 0;
}

.top-nav-sub-cat .navbar-collapse {
  left: 0 !important;
  z-index: 99;
  width: 56%;
}

.active-link-catagory button {
  color: #E33CC7;
}

.active-link-catagory {
  border-bottom: 1px solid #E33CC7;
}


@media only screen and (max-width: 900px) {
  .form-event-search .d-flex.searchbtn.form-group {
    width: 100% !important;
  }

  .section-event-banner h1 {
    margin-right: 0px;
  }

  .form-event-search .searchbtn .form-control {
    padding-left: 10px;
  }
}

span.geoloaction-text-wrap {
  margin-bottom: 0;
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  font-size: 55px;
}

.geoloaction-text-wrap img {
  margin-right: 0.5rem;
}

p.event-name-homepage {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 5px;
}

.map-seat-main-wraper {
  /* padding-left: 0px !important;
  padding-right: 0px !important; */
  overflow-x: hidden;
  background: #ffffff;
}

.ticketPage {
  margin-top: 100px;
}

.upcomingMain .accordion-title {
  background-color: transparent !important;
  border: 0px;
}

.upcomingMain h2 {
  font-size: 1.6em;
}

.upcomingMain h2 span {
  font-size: 1.6rem !important;
}

.upcomingMain .accordion-content {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.upcomingMain .accordion-content input {
  width: 100%;
  height: 40px;
  border: 1px solid #cdcbcb;
  padding-left: 12px;
}

.upcomingMain .accordion-content input::placeholder {
  color: #333;
  font-size: 15px;
  font-weight: 600;
}

ul.react-autosuggest__suggestions-list {
  border: 1px solid #cdcbcb;
  padding: 12px;
}

ul.react-autosuggest__suggestions-list span {
  line-height: 2;
  cursor: pointer;
}

/* .sectionTitle{
  margin-top: 2rem;
} */
.justify-content-center.upcomming-event-item.row {
  margin-top: 40px;
  margin-bottom: -20px;
}

.upcoming-event-wrap {
  display: block;
}

.upcoming-event-wrap button:hover {
  background-color: #fff;
  color: #333;
}

span.show-more-less-clickable {
  font-size: 16px !important;
}

.upcomming-wrap {
  display: flex;
  margin: auto;
  /* align-items: center; */
}

.upcomming-map-icon svg {
  margin-right: 10px;
  font-size: 22px;
  color: rgb(233, 77, 19);
  margin-top: 12px;
}

.calagory-main-section-main-wraper ul.react-autosuggest__suggestions-list {
  background-color: #fff;
  text-align: left;
  cursor: pointer;
}

.concerts-ticket-near h1 {
  font-size: 29px;
  margin: 0px;
  padding: 0px;
  margin-top: -9px;
}

.arrow-btn-concerts svg {
  color: #fff;
  font-size: 22px;
  margin-left: 20px;
  cursor: pointer;
}

.calagory-main-section-main-wraper .accordion-title {
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  border: 0;
}

.calagory-main-section-main-wraper .accordion-title .arrow-btn-concerts svg {
  fill: #ffffff;
}

.concerts-ticket-near {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.calagory-main-section-main-wraper .accordion-content {
  background-color: #fff;
  width: 62%;
  margin: 0 auto;
  margin-top: 10px;
}

.calagory-main-section-main-wraper .accordion-content input {
  width: 100%;
}

.calagory-main-section-main-wraper .accordion-content input {
  width: 100%;
  height: 40px;
  border: 1px solid #cdcbcb;
  padding-left: 12px;
}

.calagory-main-section-main-wraper .accordion-content input::placeholder {
  color: #333;
  font-size: 15px;
  font-weight: 600;
}

.concerts-ticket-near svg {
  color: #fff;
  font-size: 22px;
  margin-right: 5px;
}

.searchResultEvent .concerts-ticket-near svg {
  fill: #E33CC7;
}

@media (max-width:900px) {
  .ticket-search-bar {
    width: 100%;
  }

  .concerts-ticket-near h1 {
    font-size: 25px;
  }

  .calagory-main-section-main-wraper .accordion-content {
    width: 89%;
  }
}

@media (max-width:700px) {
  .concerts-ticket-near {
    margin-top: 40px;
  }
}

@media (max-width:560px) {
  .search-result-main-wraper {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .calagory-main-section-main-wraper.col-sm-12 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .concerts-ticket-near {
    margin-top: 0px !important;
  }

  .concerts-ticket-near h1 {
    font-size: 22px;
  }

  .event-page-section-second-wraper {
    padding-top: 22px;
    padding-bottom: 0px;
  }
}

.event-page-section-second-wraper .navbar-light .navbar-nav .nav-link {
  color: #333 !important;
  margin-left: 0px;
  margin-right: 17px;
}

.event-page-section-second-wraper .navbar {
  padding: 0;
  border-bottom: 1px solid #b8b4b4;
  padding-bottom: 10px;
}

.event-page-section-second-wraper {
  padding-top: 35px;
}

.event-page-section-second-wraper .navbar-light .navbar-toggler {
  display: none;
}

.event-page-section-second-wraper .navbar-nav {
  flex-direction: row;
}

/* .search-result-main-wraper{
  margin-top: 100px;
} */
p.text-all-event-view {
  margin-bottom: 0px;
  cursor: pointer;
}

.top-nav-sub-cat .navbar-light .navbar-toggler-icon {
  background-image: url('/public/images/filter.png');
  background-repeat: no-repeat;
  background-origin: content-box;
}

#venue-map {
  overflow: visible !important;
  margin-left: -15px !important;
}

#sea-inventory-slider-with-filterBtn .sea-inventory-slider button.slick-prev {
  top: 51px !important;
}

#sea-inventory-slider-with-filterBtn .sea-inventory-slider button.slick-next {
  top: 51px !important;
}

#sea-inventory-slider-with-filterBtn .sea-inventory-slider button.slick-prev,
#sea-inventory-slider-with-filterBtn .sea-inventory-slider button.slick-next {
  background: #fff;
  color: #337ab7;
  cursor: pointer;
  font-size: 14px;
  padding: 11px 0;
  position: absolute;
  top: 55px;
  z-index: 9999;
}

#sea-inventory-slider-with-filterBtn .sea-inventory-slider {
  margin-top: -30px;
  height: 87px;
}

#tn-maps .slick-next:before {
  font-size: 30px !important;
}

#tn-maps .slick-prev:before {
  font-size: 30px !important;
}

.error-msg-email {
  display: flex;
}

.error-msg-email span {
  font-size: 14px;
  font-weight: 500;
}


/* object-fit: contain;
    width: 100%;
    height: auto;
    object-position: 0 0;
    max-height: 210px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-left: 10px; */

@media screen and (max-width: 991px) {

  .pre-checkout-parent,
  .sea-filterCard-parent {
    width: 88%;
    padding-left: 6px;
    padding-right: 6px;
  }

  /* .map-seat-main-wraper {
    overflow: hidden;
  } */
}

.pre-checkout-parent,
.sea-filterCard-parent {
  /* width: 367px; */
  padding-right: 10px;
  padding-left: 7px;
  z-index: 99999 !important;
}

.sea-no-vfs-show-details .pre-checkout-vfs-tg-info-text {
  margin-left: -53px;
}

.sea-no-vfs-show-details .pre-checkout-vfs-tg-info-color {
  height: 38px;
  position: absolute;
  right: -55px;
}

.sea-no-vfs-show-details .pre-checkout-back-row {
  border-bottom: 1px #ccc solid;
  height: 64px;
  position: static;
  display: flex;
  flex-direction: row-reverse;
  padding-left: 0px;
}

.timer--tag {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
}

.timer--tag p span {
  display: block;
  /* color: #f45e16; */
  color: #E33CC7
}

.timer--tag p {
  display: inline-block;
  border: 1px dashed #E33CC7;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  text-align: center;
}

@media screen and (min-width: 600px) and (max-width: 900px) {

  .pre-checkout-parent,
  .sea-filterCard-parent {
    top: 15%;
  }
}

@media screen and (min-width: 576px) and (max-width: 766px) {
  .TX--inner--Banner.faq-main-section {
    padding-top: 138px;
  }
}

/* @media screen and (min-width: 991px) and  (max-width: 1107px){
  form.form-search-main-po {
    position: absolute;
    top: -47px;
    width: 82%;
    left: 0%;
}
} */
.ticket-confirm-main-section {
  margin-top: 140px;
  text-align: center;
  padding-bottom: 0px;
  background: #ffffff;
}

.recipt-data-ticket-confirm {
  /* padding-top: 0px; */
  background: #ffffff;
}

.checkOut {
  margin: 0;
  padding-top: 70px;
  padding-bottom: 60px;
  background: #fff;
  margin-top: 100px;
}

.ticket-confirm-new {
  background-color: #fff;
}

.loader-main-ticketinfodata {
  background: #bebebe;
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TX--inner--Banner.section-event-banner {
  padding-top: 0px;
  padding-bottom: 0px !important;
  height: 63px;
  /* margin-bottom: -28px; */
}

.reciept-download {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.error-lockid h3 {
  line-height: 1.6;
  margin-bottom: 26px;
}

.error-lockid {
  width: 50%;
}

.paypal-checkout-message {
  z-index: 9999 !important;
  background-color: #333 !important;
  display: block !important;
}

.paypal-checkout-loader {
  display: block !important;
  z-index: 9999 !important;
  display: block !important;
}

.paypal-checkout-continue {
  display: block !important;
  z-index: 9999 !important;
  display: block !important;
}

.paypal-checkout-sandbox-iframe .paypal-overlay-context-iframe .paypal-checkout-modal {
  background-color: #333 !important;
  z-index: 99999 !important;
  display: block !important;
}

.paypal-checkout-sandbox-iframe .paypal-checkout-logo {
  background-color: #333 !important;
  z-index: 99999 !important;
  display: block !important;
}

.paypal-checkout-sandbox-iframe .paypal-overlay-context-iframe .paypal-checkout-message,
.paypal-overlay-context-iframe .paypal-checkout-continue {
  display: block !important;
  background-color: #333 !important;
  z-index: 99999 !important;
}

iframe .paypal-overlay-context-iframe .paypal-checkout-iframe-container {
  display: none !important;
}

.loder-singlepage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-loader {
  text-align: center;
}

@media screen and (max-width: 990px) {
  .login-form-main-wraper {
    padding: 0px 35px;
  }

  .loginForm form {
    padding: 0px !important;
  }

  .sign-up-main-wraper {
    padding: 0px 35px;
  }


  .react-autosuggest__input {
    border-radius: 10px;
  }

  /* .react-autosuggest__suggestions-container{
  background-color: #fff;
} */

  .sectionSeparator {
    background-color: #fff;
    clip-path: polygon(0% 20%,
        /* left top */
        100% 90%,
        /* right top */
        100% 100%,
        /* right bottom */
        0% 100%
        /* left bottom */
      );
  }

  .hometitle {
    text-transform: revert !important;
  }
}

/* @media screen and (max-width: 768px) {
  .pre-checkout-parent {
    top: 162px !important;
  }
} */

.sign-up-main-wraper select {
  word-wrap: normal;
  height: 40px;
  padding-left: 4px;
  border-radius: 5px;
  border-color: #cdcdcd;
  /* margin-left: 15px; */
  width: 100%;
}

.react-responsive-modal-overlay {
  z-index: 2000;
}

.featureSection {
  padding-bottom: .5rem;
  padding-top: .5rem;
  background-color: black;
  color: white;
}


.featureSection h4 {
  font-size: 1.2rem;
  color: white;
}

.featureCloseButton {
  background-color: #E33CC7 !important;
}

.featureCloseIcon path {
  fill: white;
}

.featureOverlay {
  height: 100%;
}

.featureModal {
  max-width: 90%;
}

.buttonMainWraper {
  display: flex;
  justify-content: space-between;
  margin-top: 19px;
}

.backToTopBtn {
  padding-top: 20px !important;
  position: relative;
  z-index: 2;
}

.backToTopBtn button:hover {
  background-color: #fff;
  color: #333;
}

@media screen and (max-width: 540px) {
  .buttonMainWraper {
    flex-flow: wrap;
    justify-content: flex-start;
  }

  .buttonMainWraper button {
    font-size: 12px;
    margin-right: 10px;
  }

  /* .Anywhere {
  margin-top: 12px;
} */
  .upcomingMain {
    overflow: hidden;
  }

  .upcomingMain h2 span {
    font-size: .9rem !important;
  }

  .calagory-main-section-main-wraper.col-sm-12 {
    margin-bottom: 0px !important;
  }

  .yourLocation {
    margin-bottom: 14px;
  }

}

.faqPage .accordion-title {
  cursor: pointer;
}

.iconCancleModel {
  width: 24px;
  height: 21px;
  background: #E33CC7;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -13px;
  right: 0px;
  margin-bottom: 20px;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
}

.modal-open .modal {
  margin-top: 9px;
}

.goto-checkout h4 {
  font-size: 20px;
  border-bottom: 1px solid #c0bebe;
  padding-bottom: 20px;
}

.Continue-guest h3 {
  font-size: 22px;
  padding-top: 20px;
}

.or-signup {
  text-align: center;
  border-bottom: 1px solid #c0bebe;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 700px) {
  .guestModel {
    width: 100% !important;
  }

  .loginForm-Model {
    width: 100% !important;
  }
}

.guestModel {
  width: 595px;
}

.textLoginConfirm {
  margin-top: 10px;
  font-size: 17px;
  padding-bottom: 10px;
}

@media screen and (max-width: 500px) {

  /* .pre-checkout-back-to-list{
    width: auto!important;
  } */
  .main-sign-up-page {
    padding: 20px 25px !important;
  }

  .loginForm {
    padding: 20px 0px;
  }

  .bounce-img-loader {
    width: 40px !important;
    height: 40px !important;
  }

  .Existing-user h3 {
    font-size: 15px;
    margin-bottom: 15px;
    margin-right: 20px;
  }

  .Existing-user button {
    padding: 5px 9px !important;
    font-size: 10px !important;
  }

  .ticket-search-bar .form-control::placeholder {
    font-size: 16px;
  }

  .guestModel .sign-up-main-wraper {
    padding: 0px 10px !important;
  }

  .list-inner-ctn {
    height: 600px !important;
    margin-bottom: 60px !important;
  }
}

.section-info-popup {
  z-index: 999 !important;
}

.Existing-user h3 {
  font-size: 15px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.loginForm-Model {
  width: 600px;
  margin-top: 26px;
}

.loginmodel-padding {
  padding: 15px;
}

span.accounSpan {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.account-create-login-model {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Forgot-textLogin-Model {
  font-weight: 700;
  text-decoration: underline !important;
  color: #333 !important;
}

.align-items-lg-stretch.model-loginForget-pass.row {
  width: 500px;
}

.Existing-user {
  display: flex;
  align-items: baseline;
  justify-content: start;
  margin-top: 12px;
  margin-bottom: 20px;
  border-bottom: 1px solid #b9b3b3;
  padding-bottom: 16px;
}

.bounce-img-loader img {
  width: 70px;
  display: block;
  text-align: center;
  /* color: #990000; */
  color: #E33CC7;
  font: normal 45px 'FontAwesome';
  line-height: 60px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.bounce-img-loader {
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  margin-left: -30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

.spinn_col {
  color: #E33CC7;
}

.ticketPage * {
  color: #fff;
}

.ticketPage ul,
.ticketPage ul li {
  padding-left: 0;
}


.text-wrapper h5 {
  font-size: 12px;
  color: #fff;
  margin-bottom: 5px;
}

.text-wrapper {
  width: 50%;
  float: right;
}

.text-wrapper li {
  font-size: 12px;
  color: #fff;
}

div#event-info-area div#event-info-right-col {
  display: none !important;
}

/******************  c---venue---------------///////////*/

div#event-info-area .event-info-date {
  /* border: none;
    display: flex;
    width: 100%;
    gap: 8px;
    align-items: center;
    font-style: italic;
    margin-left: 8px; */
  display: none;
}

div#event-info-area {
  display: none;
}



div#event-info-area .event-info-inner-ctn {
  display: flex;
  flex-direction: column-reverse;
}

div#event-info-area .event-info-date .event-info-date-time.event-info-date-loc {
  padding-bottom: 0 !important;
}

div#event-info-area .event-info-date .event-info-date-date.event-info-date-loc {
  padding: 0 !important;
}

div#event-info-area h1#sea-event-info-name {
  display: none;
}

div#map-list-holder {
  height: 618px !important
}


div#tn-maps {
  height: 718px !important;
}


@media (max-width: 991px) {
  div#precheckout-parent>div#sea-pre-checkout-wrapper {
    display: flex !important;
    justify-content: end !important;
    flex-direction: column;
    height: fit-content;
    background: #fff;
  }

  div#bannersFiltersDiv div#sea-list-disclosure-bottom {
    display: none;
  }

  div#pre-checkout-scrollable {
    padding-top: 20px;
  }

  div#sea-inventory-slider-with-filterBtn {
    border-top: solid 1px #E6E6E6;
  }

  .pre-checkout-delivery-ctn+div#pre-checkout-right {
    display: none;
  }

  .sea-quantity-slider {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  /* .ticketPage ul li span {
  display: none;
} */
  .text-wrapper {
    width: 100%;
    margin-top: 18px;
  }

}

div#event-info-area .event-info-content.event-info-col {
  display: none;
}

div#event-info-area {
  padding-top: 0;
  padding-left: 6px;
}

span.no-hidden-charges-text {
  padding-right: 9px;
}


/********************************************************************************/



@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.loader-seetmap {
  display: none;
}


@media screen and (max-width: 370px) {
  .loader-seetmap {
    position: absolute;
    z-index: 9998;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
  }
}

.react-responsive-modal-overlay {
  z-index: 99999 !important;
}

.hideMapFull #map-ctn {
  overflow: hidden;
}

.fade.modal.show {
  z-index: 99999;
}

.recipt-data-ticket-confirm .download-pdf-eticket {
  margin-top: 13px;
}

.recipt-data-ticket-confirm .download-pdf-eticket p {
  width: 100%;
}

.Receipt-purchase .download-pdf-eticket {
  text-align: center !important;
}

.recipt-data-ticket-confirm .Receipt-purchase {
  display: unset;
}

.card-img-top {
  height: 77px;
  object-fit: contain;
  background: #fff;
}

.map-list-view h5 {
  margin-bottom: 25px;
  font-weight: bold;
}

#table-price td p {
  margin: 0 !important;
}

.custom-alert {
  background-color: #ffc10791;
  padding: 1rem;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}

.custom-alert h4 {
  margin-bottom: 0.65rem;
}

section#shadow-host-companion {
  padding: 0;
}

.payment--form {
  display: flex;
  flex-flow: row wrap;
  column-gap: 10px;
}

.payment--form #card-number,
.payment--form #expiration-date,
.payment--form #cvv,
.payment--form input#card-holder,
.payment--form input#card-billing-address-country {
  height: 50px;
  flex: 1 1 100%;
  margin-bottom: 1rem;
  background: #ffffff;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.payment--form #expiration-date,
.payment--form #cvv {
  flex: 1 1 100%;
  max-width: calc(50% - 5px);
}

.payment--form button {
  width: 100%;
  text-align: center;
  background: #E33CC7;
  color: #fff;
  font-size: 1rem;
  padding: 0.85rem 1.5rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  border: 0;
  text-transform: capitalize;
  font-weight: 600;
}

.payment--form button:hover,
.payment--form button:focus,
.payment--form button:focus-within {
  background: #1a0a23;
  color: #fff;
}

.sign-up-password.form-group {
  position: relative;
}

.sea-ticket-row-fees-included {
  display: none;
}

.sign-up-password.form-group .pasword-svg {
  position: absolute;
  right: 10px;
  top: 43px;
}

#precheckout-parent #moreDeliverycontent {
  height: auto !important;
  overflow: visible !important;
}

#precheckout-parent .seeMoreDeliveryNotelink.toggleViewClass.sea-show-full-notes-packages {
  display: none;
}

span.visually-hidden {
  opacity: 0;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid #e33cc7;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.sea-quantity-items .slick-prev::before, .sea-quantity-items .slick-next::before {
	color: #000;
}

.sea-quantity-items .slick-prev {
	top: auto;
	bottom: -16px;
	margin: 0;
	height: auto;
}

.navBarWrapper .dropdown-menu {
  left: auto;
  right: 0;
}

/*-=--=-=-=-=
Media Css
-=-=-==-=-=*/
@media (min-width:1440px) {
  .eventSlider .slick-slider .slick-list {
    width: unset;
    width: 100%;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .bannerContent h2 {
    font-size: 100px;
    line-height: 100px;
  }

  .pdf-viewer {
    width: 500px;
    height: 600px;
  }

  .eventDesc h3 span {
    font-size: 15px;
  }

  .eventDesc h3 span {
    font-size: 15px;
    line-height: 1.4;
  }

  .eventDesc h5 {
    font-size: 1rem;
  }

  .eventDesc .btn-primary {
    font-size: 0.8rem;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .map-seat-main-wraper {
    padding-top: 20px;
  }

  .map-ctn-tg-on-right {
    height: 75px !important;
  }

  .carousel-caption h1 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 2rem;
  }

  .carousel-inner .locationTag {
    margin-top: 1rem;
  }

  .mainNav {
    padding: 1.5rem 0;
  }

  .topBar {
    padding: 0.5rem 0
  }

  .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
    margin-left: 0.5rem;
  }

  .eventCol {
    margin-bottom: 1rem
  }

  .eventCol img {
    width: 100%;
  }

  .footList .list-group-item {
    margin: 0px 0.3rem;
  }
}

@media screen and (min-width: 601px) {
  header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999 !important;
    padding: 0;
    background-color: #080015;
  }
}

@media (max-width:991px) {
  .top-nav-sub-cat button {
    color: #fff;
  }

  .top-nav-sub-cat button:hover {
    color: #fff !important;
  }

  .active-link-catagory button {
    color: #fff !important;
  }

  .carousel-caption h1 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 2rem;
  }

  .carousel-inner .locationTag {
    margin-top: 1rem;
  }

  .carousel-inner .locationTag strong {
    font-size: 17px;
    line-height: 42px;
  }

  .navbar-toggler {
    background-color: #fff;
    margin-left: auto;
  }

  .mainNav {
    padding: 1.5rem 0;
  }

  .topBar {
    padding: 0.5rem 0
  }

  .navbar-nav {
    align-items: flex-start;
    padding: 0;
  }

  .navbar-collapse {
    position: absolute;
    right: 0;
    background: #000;
    top: 100%;
    width: auto;
    padding: 0.8rem;
    min-width: 150px;
    z-index: 9999999 !important;
  }

  .navbar-light .navbar-nav .nav-link {
    margin-left: 0;
    display: block;
    /* width: 100%; */
    padding: 0;
  }


  div#list-ctn {
    position: relative;
  }
  

  div#list-ctn:before {
    content: 'Primary/secondary marketplace, prices may be above face value.';
    /* position: absolute; */
    width: 100%;
    height: fit-content;
    top: -46px;
    font-weight: 600;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    border-top: 1px solid #e6e6e6;
    padding: 10px;
  }

  .mapMain.map-seat-main-wraper {
    padding: 0;
  }

  .ticketPage .breadcrumb {
    padding: 6px 0 9px 0;
  }

  .ticketPage .text-wrapper {
    display: none;
  }

  .eventCol {
    margin-bottom: 1rem;
  }

  .newsCol .imageWrap {
    min-height: 0;
  }

  .newsCol .imageWrap img {
    width: 100%;
    height: auto;
  }

  .subscribeMain form {
    width: auto;
    margin: auto;
  }

  footer .list-group {
    flex-flow: row wrap;
  }

  .footList .list-group-item {
    flex: 0 0 25%;
  }

  .mainNav hr {
    display: none
  }

  .cart .items .cartSection {
    width: 90%;
    display: block;
    float: left;
  }

  .cart .items .cart.qtyWrap {
    width: 10%;
    text-align: center;
    padding: .5em 0;
    float: right;
  }

  .cart .items .qtyWrap:before {
    content: "QTY";
    display: block;
    font-family: "Montserrat", sans-serif;
    padding: .25em;
    font-size: .75em;
  }

  .cart .items .prodTotal,
  .cart .items .removeWrap {
    display: none;
  }

  .cart .items .cartSection .itemImg {
    width: 25%;
  }

  .promoCode,
  .subtotal {
    width: 100%;
  }

  .pdf-viewer {
    width: 400px;
    height: 500px;
  }

  .eventPriceButton {
    font-size: 0.7rem;
  }

  .featureSection {
    font-size: 0.65rem;
  }

  div#map-ctn {
    height: 350px !important;
    margin: 0;
    padding: 0;
  }

  div#venue-map {
    top: 0 !important;
    margin: 0 !important;
    height: 100% !important;
    left: -15px;
  }

  .sea-map-inner {
    height: 100% !important;
    width: 100%;
  }

  div#list-ctn {
    top: 60px !important;
  }

  div#venue-map-zoom-controls {
    display: block;
  }

  body:has(.pre-checkout-parent.sea-no-vfs-show-details.sea-precheckout-parent-active),
  body:has(.pre-checkout-parent.sea-no-vfs-show-details.sea-precheckout-parent-active) html {
    overflow: hidden;
  }

  .bannerContent h2 {
    font-size: 75px;
  }

  .eventSlider {
    padding: 100px 0 40px 0;
  }

  .seatsWrap .itemsWrap .mainwrapper {
    padding: 20px;
  }

  .seatsWrap .itemsWrap .headingLinkWrap h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .seatsWrap button.arrowIcon {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  .seatsWrap button.arrowIcon img {
    max-width: 14px;
  }

  .bottomBarrelWrap .headingwrap h2 {
    font-size: 50px;
  }

  .bottomBarrelWrap button.slick-arrow.slick-prev,
  .bottomBarrelWrap button.slick-arrow.slick-next {
    top: -65px;
    right: 55px;
    width: 40px;
    height: 40px;
  }

  .bottomBarrelWrap .headingwrap h2+p {
    font-size: 16px;
  }

  .bottomBarrelWrap h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .bottomBarrelWrap p {
    font-size: 15px;
    line-height: 22px;
  }

  .bottomBarrelWrap .slick-slide button,
  .bottomBarrelWrap .slick-slide button a {
    font-size: 14px;
    line-height: 28px;
    display: block;
    text-align: left;
  }

  .bottomBarrelWrap {
    padding: 120px 0 50px 0;
  }

  .comeOnFolksCTA {
    padding: 70px 0 30px;
  }

  .footerWrap ul.footList.list-group h4 {
    font-size: 15px;
  }

  ul.footList.list-group a {
    font-size: 14px;
    line-height: 28px;
  }

  .footerWrap .copyright p,
  .footerWrap .copyright .bottomList .list-group-item {
    font-size: 13px;
  }

  footer .bottomList {
    justify-content: flex-end;
    margin-bottom: 0;
  }

  .comeOnFolksCTA button {
    padding: 18px 28px;
    font-size: 20px;
  }

  footer.footerWrap {
    padding: 5rem 0 2rem;
  }

  .footerWrap form {
    align-items: flex-end;
    gap: 10px;
    flex-flow: column wrap;
  }

  .upcomingEvents h2, span.geoloaction-text-wrap {
    font-size: 35px;
  }

  .bannerWrapper {
    max-height: 590px;
    height: 590px;
  }

  .slick-prev {
    left: 40px;
  }

  .slick-next {
    right: 40px;
  }

  .eventSlider .list-group-item h4 {
    margin: 8px 0;
  }

  .bannerContent input#formText {
    max-width: 420px;
  }
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 200px;
  }

  .notfound .notfound-404 h1 {
    font-size: 200px;
  }

  .TX--inner--Banner {
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }

  #sea-mp {
    position: inherit !important;
  }

  div#event-info-area {
    position: inherit;
    display: none;
  }

  div#list-ctn {
    position: inherit;
  }

  .venue-map {
    position: absolute;
  }

  div#sea-mp-list {
    display: none;
  }

  .sea-mp-header,
  .sea-mp-map-shower,
  .sea-mp-filter-row {
    height: auto !important;
  }

  div#scrollableDiv {
    padding: 0;
  }

  button.eventCell.btn.btn-primary {
    padding: .5rem;
  }

  #scrollableDiv span {
    font-size: 15px;
  }

  span.eventPrice {
    margin-left: 20px !important;
  }

  .eventDate .event-text-data {
    width: 100px;
  }

  .bannerContent input#formText,
  .bannerContent button {
    height: 70px;
  }

  .seatsWrap .itemsWrap .mainwrapper {
    margin-bottom: 2rem;
  }

  .bottomBarrelWrap {
    padding: 60px 0 50px 0;
  }
}

@media screen and (max-width: 600px) {
  .loader-seetmap {
    display: block;
  }

  .loader-seetmap {
    position: absolute;
    z-index: 9998;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
  }

  .loader-seetmap .bounce-img-loader {
    margin-top: -1090px !important;
  }

  .upcomingEvents h2 {
    color: var(--White, #FFF);
    font-size: 28px;
    line-height: 40px;
    /* 142.857% */
    margin-bottom: 0px;
  }

  .upcomingEvents h2 {
    color: var(--White, #FFF);
    font-size: 28px;
    line-height: 40px;
    /* 142.857% */
    margin-bottom: 30px;
  }

  span.geoloaction-text-wrap {
    font-size: 30px;
  }

  .searchwrap span.input-group-text {
    font-size: 14px;
    line-height: 20px;
  }

  .searchwrap input.form-control {
    font-size: 14px;
    line-height: 20px;
  }

  .searchwrap .input-group {
    padding: 10px 12.5px;
    height: auto;
  }

  .searchwrap img {
    width: 16px;
    height: 16px;
  }

  .upcomingEvents .headingwrap {
    display: flex;
    flex-direction: row;
  }

  .bottomBarrelWrap .headingwrap {
    margin-bottom: 0;
  }

  .eventWrapper.card .card-title.h5 {
    font-size: 20px;
    /* 170% */
  }

  .bottomBarrelWrap .slick-slide {
    padding: 30px 20px;
  }

  .comeOnFolksCTA button {
    margin: 0 auto;
    padding: 11px 35px;
    font-size: 16px;
    line-height: 34px;
  }

  .eventWrapper.card .list-group-item.price {
    font-size: 20px;
    /* 150% */
  }

  .comeOnFolksCTA {
    background-position: center center;
  }

  .blogWrap .headingwrap h2 {
    color: var(--White, #FFF);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 142.857% */
    text-align: center;
  }

  .blogWrap button.slick-arrow.slick-prev,
  .blogWrap button.slick-arrow.slick-next {
    display: none !important;
  }

  .blogWrap {
    padding: 80px 0;
  }

  .blogWrap .headingwrap {
    margin: 0;
  }

  .blogWrap h4 {
    color: var(--White, #FFF);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    /* 170% */
    margin-bottom: 30px;
  }

  footer.footerWrap:before {
    background: linear-gradient(180deg, #090811 0%, rgba(22, 70, 80, 0.90) 100%);
  }

  footer.footerWrap:before {
    background: linear-gradient(180deg, #090811 0%, rgba(22, 70, 80, 0.90) 100%);
  }

  img#footerlogo {
    width: 130px;
    margin: 0 auto;
  }

  footer.footerWrap {
    text-align: center;
  }

  ul.socialGroup.list-group {
    margin-top: 24px;
    margin-left: auto !important;
    margin-right: auto !important;
    width: fit-content;
  }

  footer.footerWrap:before {
    background: linear-gradient(180deg, #090811 0%, rgba(22, 70, 80, 0.90) 100%);
  }

  img#footerlogo {
    width: 130px;
    margin: 0 auto;
  }

  footer.footerWrap {
    text-align: center;
  }

  ul.socialGroup.list-group {
    margin-top: 24px;
    margin-left: auto !important;
    margin-right: auto !important;
    width: fit-content;
  }

  .iconshape {
    display: none;
  }

  .footerWrap ul.footList.list-group h4 {
    border-radius: 8px;
    width: 100%;
    padding: 0 0 5px 0;
    margin: 0 0 15px 0;
    border-radius: 0;
    text-align: left;
    text-transform: uppercase;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    width: 100%;
  }

  ul.footList.list-group a {
    text-align: left;
    padding: 0;
  }

  ul.footList.list-group {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.10);
    align-items: start;
    margin-bottom: 15px;
    padding: 1rem;
  }

  footer.footerWrap>.container:last-child p {
    color: var(--White, #FFF);
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.16px;
  }

  footer.footerWrap>.container:last-child p {
    color: var(--White, #FFF);
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.16px;
  }

  footer.footerWrap>.container:last-child ul.bottomList.list-group {
    display: none;
  }

  .iconshape {
    display: none;
  }

  .blogWrap .slick-slide button {
    color: #E33CC7;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .upcomingEvents .headingwrap {
    margin-bottom: 30px;
  }

  .searchwrap span.input-group-text {
    font-size: 14px;
    line-height: 20px;
  }

  .seatsWrap .headingwrap h2 {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .seatsWrap .headingwrap p {
    font-size: 16px;
    line-height: 24px;
    width: 90%;
  }

  .seatsWrap .headingwrap p button {
    font-size: 16px;
    line-height: 24px;
    padding: 0 0 0 7px;
  }

  .searchwrap input.form-control {
    font-size: 14px;
    line-height: 20px;
  }

  .seatsWrap .itemsWrap .headingLinkWrap h2 {
    font-size: 32px;
    line-height: 46px;
    /* 143.75% */
  }

  .seatsWrap .headingLinkWrap {
    margin-bottom: 24px;
  }

  .seatsWrap button.arrowIcon {
    width: 40px;
    height: 40px;
  }

  .seatsWrap .itemsWrap .mainwrapper {
    padding: 30px 11px;
  }

  .seatsWrap .itemsWrap .headingLinkWrap h2 {
    font-size: 32px;
    line-height: 46px;
    /* 143.75% */
  }

  .seatsWrap .headingLinkWrap {
    margin-bottom: 24px;
  }

  .seatsWrap button.arrowIcon {
    width: 40px;
    height: 40px;
  }

  .seatsWrap .itemsWrap .mainwrapper {
    padding: 30px 11px;
  }

  .nearCTA:before {
    width: 115.642px;
    height: 115.985px;
    background-size: contain;
  }

  .nearCTA:after {
    width: 115.642px;
    height: 115.985px;
    background-size: contain;
  }

  .nearCTA .searchwrap:after {
    width: 47.807px;
    height: 48.044px;
    background-size: contain;
    top: -111px;
    right: 0;
  }

  .nearCTA .searchwrap:before {
    width: 47.807px;
    height: 48.044px;
    background-size: contain;
    bottom: -72px;
    left: 0;
  }

  .nearCTA button {
    color: var(--White, #FFF);
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    padding: 18px 7px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .nearCTA button img {
    width: 16px;
    height: 16px;
  }

  .nearCTA .searchwrap .input-group {
    height: 60px;
  }

  .nearCTA .headingwrap .col-sm-12 h2 {
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
  }

  .nearCTA {
    padding: 140px 0;
  }

  .bottomBarrelWrap {
    padding: 80px 0;
  }

  .bottomBarrelWrap .headingwrap h2 {
    font-size: 28px;
    line-height: 40px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .bottomBarrelWrap .headingwrap h2+p {
    color: #B5B5B8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .bottomBarrelWrap .slick-slide {
    margin-top: 30px;
  }

  .bottomBarrelWrap:before {
    background: #6300FF;
    bottom: unset;
    top: 0;
    right: unset;
    left: -20px;
    width: 238px;
  }

  .bottomBarrelWrap button.slick-arrow.slick-prev,
  .bottomBarrelWrap button.slick-arrow.slick-next {
    display: none !important;
  }

  .bottomBarrelWrap .slick-slide .imgwrapper {
    width: 80px;
    height: 80px;
    margin-bottom: 50px;
  }

  .bottomBarrelWrap h4 {
    color: var(--White, #FFF);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    /* 150% */
    margin-bottom: 16px;
  }

  .bottomBarrelWrap p {
    color: #B5B5B8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    margin-bottom: 30px;
  }

  .bottomBarrelWrap .slick-slide {
    padding: 30px 20px;
  }

  .searchwrap .input-group {
    padding: 10px 12.5px;
    height: auto;
  }

  .searchwrap img {
    width: 16px;
    height: 16px;
  }

  .upcomingEvents .headingwrap {
    display: flex;
    flex-direction: column;
  }

  .active-accordian {
    margin-bottom: 30px;
  }

  .bannerContent h2 {
    font-size: 42px;
    line-height: 52px;
  }

  div#responsive-navbar-nav {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: auto;
    justify-content: flex-start;
    opacity: 0;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
  }

  span.whiteShape {
    width: 24px;
    height: 36px;
    display: inline-block;
    background: #fff;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    position: relative;
    margin-left: 0px;
    margin-right: -4px;
  }

  span.pinkShape {
    width: 24px;
    height: 36px;
    display: inline-block;
    background: #E33CC7;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    position: relative;
    margin-right: 0px;
  }

  .searchbtn.form-group {
    flex-direction: column;
    margin-top: 20px;
  }

  .bannerWrapper {
    max-height: 700px;
    height: 700px;
    margin: -100px 0 0 0;
  }

  .searchbtn.form-group button {
    width: 100%;
    margin: 15px 0 0 0 !important;
  }

  div#responsive-navbar-nav.navbar-collapse.collapse.show {
    display: flex;
    opacity: 1;
    align-items: flex-start;
  }

  .navBarWrapper .navbar-light .navbar-nav.rightSideNavItems .nav-link:first-child {
    padding: 0;
    text-align: left;
    align-self: flex-start;
  }

  .navBarWrapper .navbar-light .navbar-nav.rightSideNavItems .nav-link:hover:after {
    content: unset;
  }

  header {
    z-index: 5 !important;
  }

  .navBarWrapper {
    max-height: 88px;
  }

  .sticky-top {
    z-index: 0px !important;
  }

  #tn-maps {
    margin-bottom: 280px;
  }

  .main-contact-page form {
    padding: 20px 20px !important;
  }
}

@media (max-width:575px) {
  .checkOut {
    padding-top: 40px !important;
    margin: inherit !important;
    padding-left: 0px;
    padding-right: 0px;
  }

  header {
    background: #080015;
  }

  .media {
    display: unset;

  }

  .topBar ul {
    justify-content: center
  }

  .navbar-toggler {
    position: absolute;
    right: 21px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    background: #48B6CD;
    color: #fff !important;
  }

  .navbar-light .navbar-toggler-icon {
    width: 2rem;
    height: 2rem;
    background-image: url('/public/images/navbar.svg');
  }

  .carousel-caption h1 {
    font-size: 23px;
    line-height: 33px;
    margin-bottom: 2rem;
  }

  .carousel-caption form {
    width: 85%;
    margin: auto;
  }

  .sectionTitle h2 {
    font-size: 33px;
    line-height: 38px;
  }

  .cta h2,
  .subscribeMain h2 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 1rem;
  }

  .footList .list-group-item,
  .socialGroup .list-group-item {
    margin: 4px 0rem;
  }

  footer .footList {
    flex-flow: column;
  }

  .payment--form #expiration-date,
  .payment--form #cvv {
    max-width: calc(100% - 0px);
  }

  footer .bottomList {
    justify-content: center;
  }

  footer.footerWrap .copyright::before {
    right: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 162px;
  }

  .notfound .notfound-404 h1 {
    font-size: 162px;
    height: 150px;
    line-height: 162px;
  }

  .notfound h2 {
    font-size: 16px;
  }

  .pdf-viewer {
    width: 350px;
    height: 380px;
  }

  div#list-ctn:before {
    top: -55px;
  }

  div#list-ctn:before {
    top: -9px;
  }

  div#list-ctn {
    position: relative;
    padding-top: 48px !important;
  }

  .bannerContent h2 {
    font-size: 32px;
    line-height: 40px;
  }

  div#pre-checkout-est-section-calc span {
    font-size: 14px;
  }

  div#pre-checkout-est-section-calc span {
    font-size: 13px;
  }

  #pre-checkout-est-section-calc:after {
    content: 'No hidden fees. Always.';
    color: #108a24;
    display: block;
    font-size: 13px;
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    top: 0px;
    visibility: visible;
    right: 0;
    left: unset;
    font-weight: 700;
  }

  .eventSlider .list-group-item h4 {
    font-size: 16px;
  }

  .eventSlider .list-group-item strong {
    font-size: 18px;
  }
  .sea-no-vfs-show-details .pre-checkout-vfs-tg-info-color {
    right: 20px !important;
  }
}